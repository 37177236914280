// File imports
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import noImageFound from "../../../Assets/Images/noImageFound.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../Assets/Styles/Containers/_showMoreLess.scss";
import { useNavigate } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, TextField } from "@mui/material";
import "@pqina/pintura/pintura.css";
import { UpdateTemplateDataSpecific } from "../../Helpers/ApiCall";
import { filterObjectsUptoLine } from "../../Helpers/HelperFunctions";
import { GetUTCTime } from "../../Helpers/GetUTCTime";

const ShowMoreLess = (children) => {
  const { templateId, categoryUuid, lineUuid, commentUuid, categoryId, action, mediaData, matches, isNarrative, addCommentFromLine } = children;
  const storeData = useSelector((state) => state.CommonReducer);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  // media
  const [videoEditor, setVideoEditor] = useState(false);
  const [videolink, setVideoLink] = useState("");
  const [videoId, SetVideoId] = useState("");
  const [click, setClick] = useState(false);
  const [editVideoObject, setEditVideoObject] = useState("");
  const [caption, setCaption] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  const [captions, setCaptions] = useState(mediaData.map((image) => image.description || ""));

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const pinturaNavigate = (imageUrl, image) => {
    navigate(`/pinturaeditor`, {
      replace: true,
      state: {
        imageUrl,
        image,
        inspectionResponse,
        isPdf: "0",
        templateId,
        categoryId,
        categoryUuid,
        lineUuid,
        commentUuid,
        action,
        isNarrative,
      },
    });
  };
  const HandleSaveCaption = async () => {
    let templateIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = { ...inspectionResponse?.inspectionData?.data };
    delete prevData?.report_media_library_files;
    if (action === "comment") {
      let commentIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
        (itm) => itm.uuid == commentUuid
      );
      let mediaIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
        commentIndex
      ]?.comment_input_controls?.findIndex((itm) => itm.type == "Media");
      let imageIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments[commentIndex]?.comment_input_controls[
        mediaIndex
      ]?.data.findIndex((img) => img.id === currentMedia?.id);
      if (mediaIndex !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_input_controls[mediaIndex].updated_at = await GetUTCTime();
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_input_controls[mediaIndex].data[imageIndex].description = caption;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_input_controls[mediaIndex].data[imageIndex].updated_at =
          await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setInputChanged(false)
      }
    } else {
      let lineInputIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(
        (lineInpt) => lineInpt.type === "Media"
      );
      let imageIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls[lineInputIndex]?.data.findIndex(
        (img) => img.id === currentMedia?.id
      );
      if (lineInputIndex !== -1) {
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[lineInputIndex].updated_at = await GetUTCTime();
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[lineInputIndex].data[imageIndex].description = caption;
        prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[lineInputIndex].data[imageIndex].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setInputChanged(false)
      }
    }
  };

  let media = [];

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData]);

  useEffect(() => {
    if (mediaData) {
      setCaptions(mediaData.map((image) => image.description || ""));
    }
  }, [mediaData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inputChanged) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputChanged]);

  const renderTextField = (index, image) => (
    <TextField
      id="new_des_input"
      className="caption-input-textfield"
      size="small"
      variant="outlined"
      type="text"
      key={index}
      value={captions[index] || ""}
      placeholder="+ Add caption"
      name="Add caption"
      onBlur={() => inputChanged && HandleSaveCaption()}
      onChange={(e) => {
        let newCaptions = [...captions];
        newCaptions[index] = e.target.value;
        setCaptions(newCaptions);
        setCaption(e.target.value);
        setInputChanged(true);
        setCurrentMedia(image);
      }}
      sx={{
        borderRadius: "4px",
        width: "155px",
        height: "34px",
        paddingTop: "8px",
        paddingBottom: "54px",
        "& .MuiOutlinedInput-root": {
          height: "34px",
          fontSize: "14px",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
        },
      }}
    />
  );

  return (
    <>
      {mediaData?.length !== 0 && mediaData?.length > 30 ? (
        isReadMore ? (
          <>
            {mediaData?.length !== 0 &&
              mediaData?.slice(0, 30)?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box onClick={() => pinturaNavigate(imageUrl, image)} component="img" className="image_container" src={imageThumbnailUrl} alt={`Image ${index + 1}`} />
                      {renderTextField(index, image)}
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={videoThumbnail}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        <PlayCircleFilledIcon
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                          className="video_play_icon"
                        />
                        {renderTextField(index, image)}
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })}
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  See More <KeyboardArrowDownIcon />
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            {mediaData?.length !== 0 &&
              mediaData?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box onClick={() => pinturaNavigate(imageUrl, image)} component="img" className="image_container" src={imageThumbnailUrl} alt={`Image ${index + 1}`} />
                      {renderTextField(index, image)}
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={videoThumbnail}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        <PlayCircleFilledIcon
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                          className="video_play_icon"
                        />
                        {renderTextField(index, image)}
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })}
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  <KeyboardArrowUpIcon /> See less{" "}
                </span>
              </div>
            </Grid>
          </>
        )
      ) : (
        <>
          {mediaData?.length !== 0
            ? mediaData?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={6} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box onClick={() => pinturaNavigate(imageUrl, image)} component="img" className="image_container" src={!click ? imageThumbnailUrl : imageUrl} alt={`Image ${index + 1}`} />
                      {renderTextField(index, image)}
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image" ? media.push({ type: "image", fileUrl: image.onlineUrl }) : media.push({ type: "video", fileUrl: image.onlineUrl })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={!videoThumbnail?.includes("undefined") ? videoThumbnail : noImageFound}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        {renderTextField(index, image)}
                        {!videoThumbnail?.includes("undefined") ? (
                          <PlayCircleFilledIcon
                            className="video_play_icon"
                            onClick={() => {
                              setVideoEditor(true);
                              SetVideoId(image?.id);
                              setEditVideoObject(image);
                              setVideoLink(videoUrl);
                            }}
                          />
                        ) : (
                          <CircularProgress className="circular_progress_icon" />
                        )}
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })
            : ""}
        </>
      )}
      {videoEditor && (
        <ImageCarousel
          inspectionResponse={inspectionResponse}
          videolink={videolink}
          mediaData={mediaData?.filter((item) => item.filetype.includes("video"))}
          videoEditor={videoEditor}
          setVideoEditor={setVideoEditor}
          videoId={videoId}
        />
      )}
    </>
  );
};

export default ShowMoreLess;
