import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Divider, Grid,Typography } from '@mui/material';
import { clearUpdatedInspectionData } from '../../Redux/Actions/CommonActions';
import { useDispatch,useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
// tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// css import
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import { checkCategoryPresent } from '../../Helpers/HelperFunctions';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import {  UpdateTemplateDataSpecific } from '../../Helpers/ApiCall';
import { useEffect, useState } from 'react';
import toast from "react-hot-toast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const AddOptionalNdStandardLineModal = (props) => {
    let {showLineModal,setShowLineModal,categoryId,templateId,isInspDetails,newLineList,handleLineSelection,selectedLines,setSelectedLines,setShowCreateNewLineModal} = props

     // states
    const [value, setValue] = useState(0);
    // redux
    const dispatch = useDispatch();
    const storeData = useSelector((state) => state.CommonReducer);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose=()=>{
        setShowLineModal(false);
        setValue(0);
    }
    const handleCreateNewLine=()=>{
        setShowCreateNewLineModal(true);
        setShowLineModal(false)
    }

    const addSelectedLinesToTemplate=async ()=>{
        let newAddedCatArray = []
        let indexValue = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id == templateId);
        let categoryIndex = storeData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories?.findIndex(itm => itm.id == categoryId);
        let prevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data));
        delete prevData?.report_media_library_files;
         for (let i = 0;i < selectedLines.length;i += 1)
         { 
            if (prevData?.inspectiontemplates[indexValue]?.categories[categoryIndex]?.lines !==null) {
                prevData.inspectiontemplates[indexValue].categories[
                  categoryIndex
                ].lines.push(selectedLines[i]);
              } else {
                prevData.inspectiontemplates[indexValue].categories[
                  categoryIndex
                ].lines = [selectedLines[i]];
              }
        newAddedCatArray.push(selectedLines[i])
      }
      let filteredData = JSON.parse(JSON.stringify(prevData));
      let filteredTemplateObject = filteredData?.inspectiontemplates[indexValue];
      let filteredCategoryObject = filteredTemplateObject?.categories[categoryIndex];
      filteredCategoryObject.lines = newAddedCatArray;
      filteredTemplateObject.categories = [filteredCategoryObject];
      filteredData.inspectiontemplates = [filteredTemplateObject];
      if(selectedLines.length !== 0 ) {
        UpdateTemplateDataSpecific(dispatch,prevData,filteredData);
      setShowLineModal(false);
      }else {
        setShowLineModal(false);
      }
    setValue(0)
      }

  return (
    <Dialog className='dialog-container-category'
    open={showLineModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-category">
      <CloseIcon onClick={handleClose} />
    </div>
    <DialogTitle className='category-dialog-title'>{"Add line"}</DialogTitle>
    <span className='category-dialog-subtitle'>Choose one or more lines from the list below.</span>
    <DialogContent className="dialog-content-category">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        
          <Box className="lineDetails__tab" sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="line_details_tab">
              <Tab className="tab__label" label="Optional" {...a11yProps(0)} />
              <Tab className="tab__label" label={`Standard`} {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* <Typography className='in_inspection'>In inspection</Typography> */}
          <br/>
          <br/>
          {/* optional tab */}
          <TabPanel value={value} index={0} className="tab_panel">
            <div className="tab_container"> 
            {value === 0  && <Typography className='bordered__text catIsFlex' 
            onClick={()=>handleCreateNewLine()}
            ><AddCircleIcon/><span className='bordered__text__content'> Create new line </span> </Typography>}                  
            {storeData?.addLineLoader === true ?
             <Box className="line_progress" sx={{ display: 'flex' }}>
             <CircularProgress />
            </Box>
            : (newLineList.length === 0 || newLineList?.optional.length === 0)?
                <p style={{textAlign:'center'}}>No optional line found</p>
                : newLineList?.optional?.map((line,index)=>(                   
              <Typography 
                  onClick={()=>handleLineSelection(line,'optional')}
                  key={index} className={`bordered__text ${selectedLines.map(line=>line.uuid).includes(line.uuid) && 'cat_selected'}`}>
                  <span className='bordered__text__content'>{line.prompt !== null ? line.prompt : "Untitled"}</span>
                  <DoneIcon className='cat_seleted_icon'/>
              </Typography>
            ))}
            </div>  
          </TabPanel>
          {/* standard tab */}
          <TabPanel value={value} index={1} className="tab_panel">
          <div className="tab_container">
          {storeData?.addLineLoader === true ?
             <Box className="line_progress" sx={{ display: 'flex' }}>
             <CircularProgress />
            </Box>
            : (newLineList.length === 0 || newLineList?.standard.length === 0)?
                <p style={{textAlign:'center'}}>No standard line found</p>
                :newLineList?.standard?.map((line,index)=>(                   
                  <Typography 
                    onClick={()=>handleLineSelection(line,'standard')}
                    key={index} className={`bordered__text ${selectedLines.map(line=>line.uuid).includes(line.uuid) && 'cat_selected'}`}>
                    <span className='bordered__text__content'>{line.prompt !== null ? line.prompt : "Untitled"}</span>
                    <DoneIcon className='cat_seleted_icon'/>
                  </Typography>
                ))}
                </div>
          </TabPanel >
          {/* {
            templateData.categories.map(category=>(
              category.is_inspection_details !== 1 &&
              <Typography onClick={()=>handleCategorySelection(category)} key={category.uuid} className={`bordered__text ${checkCategoryPresent(category,selectedCategories) && 'cat_selected'}`}>  <span className='bordered__text__content'>{category.prompt}</span>
                <DoneIcon className='cat_seleted_icon'/>
              </Typography>
            ))
          } */}
          {/* {
            newCategoryList.map((category,index)=>(                   
              <Typography 
              onClick={()=>handleCategorySelection(category)}
               key={index} className={`bordered__text ${selectedCategories.map(cat=>cat.prompt).includes(category.prompt) && 'cat_selected'}`
               }>  <span className='bordered__text__content'>{category.prompt}</span>
                <DoneIcon className='cat_seleted_icon'/>
              </Typography>
            ))
          } */}
        </Grid>

      </Grid>
    </DialogContent>
    <Divider style={{width:'100%'}}/>
    <DialogActions className='category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>addSelectedLinesToTemplate()}
      >
        Add
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default AddOptionalNdStandardLineModal