// File imports
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import noImageFound from "../../../../Assets/Images/noImageFound.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../../Assets/Styles/Containers/_showMoreLess.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, TextField } from "@mui/material";
import "@pqina/pintura/pintura.css";
import ImageCarousel from "../../LineDetails/ImageCarousel";
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";

const PdfShowMore = (props) => {
  const dispatch = useDispatch();
  const { templateId, categoryUuid, lineUuid, commentUuid, categoryId, element, action, mediaData, matches, isNarrative, addCommentFromLine } = props;
  const storeData = useSelector((state) => state.CommonReducer);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  // media
  const [videoEditor, setVideoEditor] = useState(false);
  const [videolink, setVideoLink] = useState("");
  const [videoId, SetVideoId] = useState("");
  const [click, setClick] = useState(false);
  const [editVideoObject, setEditVideoObject] = useState("");
  const [caption, setCaption] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  const [captions, setCaptions] = useState(mediaData.map((image) => image.description || ""));

  let navigate = useNavigate();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const pinturaNavigate = (imageUrl, image) => {
    navigate(`/pinturaeditor`, {
      replace: true,
      state: {
        imageUrl,
        image,
        inspectionResponse,
        isPdf: "1",
        templateId,
        categoryId,
        categoryUuid,
        lineUuid,
        commentUuid,
        action,
        element,
        isNarrative,
      },
    });
  };
  let media = [];

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData]);

  const handleSaveCaption = async () => {
    let templateIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((obj) => obj.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.pdf_data?.findIndex((obj) => obj.merge_id === element?.merge_id);
    let prevData = { ...inspectionResponse?.inspectionData?.data };
    let imageIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.pdf_data[categoryIndex].value.findIndex((img) => img.id === currentMedia?.id);
    prevData.inspectiontemplates[templateIndex].pdf_data[categoryIndex].updated_at = await GetUTCTime();
    prevData.inspectiontemplates[templateIndex].pdf_data[categoryIndex].value[imageIndex].description = caption;
    prevData.inspectiontemplates[templateIndex].pdf_data[categoryIndex].value[imageIndex].updated_at = await GetUTCTime();
    let filteredObject = filterPdfTemplateData(prevData, templateIndex, prevData.inspectiontemplates[templateIndex].pdf_data, element?.merge_id);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setInputChanged(false)
  };

  useEffect(() => {
    if (mediaData) {
      setCaptions(mediaData.map((image) => image.description || ""));
    }
  }, [mediaData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inputChanged) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputChanged]);

  const renderTextField = (index, image) => (
    <TextField
      id="new_des_input"
      className="caption-input-textfield"
      size="small"
      variant="outlined"
      type="text"
      key={index}
      value={captions[index] || ""}
      placeholder="+ Add caption"
      name="Add caption"
      onBlur={() => inputChanged && handleSaveCaption()}
      onChange={(e) => {
        let newCaptions = [...captions];
        newCaptions[index] = e.target.value;
        setCaptions(newCaptions);
        setCaption(e.target.value);
        setInputChanged(true);
        setCurrentMedia(image);
      }}
      sx={{
        borderRadius: "4px",
        width: "155px",
        height: "34px",
        paddingTop: "8px",
        paddingBottom: "54px",
        "& .MuiOutlinedInput-root": {
          height: "34px",
          fontSize: "14px",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
        },
      }}
    />
  );

  return (
    <>
      {props?.body?.length !== 0 && props?.body?.length > 30 ? (
        isReadMore ? (
          <>
            {props?.body?.length !== 0 &&
              props?.body?.slice(0, 30)?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box
                        onClick={() => pinturaNavigate(imageUrl, image)}
                        component="img"
                        className="image_container"
                        src={!imageThumbnailUrl.includes("undefined") ? imageThumbnailUrl : imageUrl}
                        alt={`Image ${index + 1}`}
                      />
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={videoThumbnail}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        <PlayCircleFilledIcon
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                          className="video_play_icon"
                        />
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })}
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  See More <KeyboardArrowDownIcon />
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            {props?.body?.length !== 0 &&
              props?.body?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box
                        onClick={() => pinturaNavigate(imageUrl, image)}
                        component="img"
                        className="image_container"
                        src={!imageThumbnailUrl.includes("undefined") ? imageThumbnailUrl : imageUrl}
                        alt={`Image ${index + 1}`}
                      />
                      {renderTextField(index, image)}
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={videoThumbnail}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        <PlayCircleFilledIcon
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                          className="video_play_icon"
                        />
                        {renderTextField(index, image)}
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })}
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  <KeyboardArrowUpIcon /> See less{" "}
                </span>
              </div>
            </Grid>
          </>
        )
      ) : (
        <>
          {props?.body?.length !== 0
            ? props?.body?.map((image, index) => {
                let imageThumbnailUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl =
                  image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                let videoThumbnail =
                  image.is_portal === 1
                    ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                    : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                return image.is_deleted !== 1 ? (
                  image.online === "online" && image.filetype === "image" ? (
                    <Grid item xs={12} sm={6} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3} key={index}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box
                        onClick={() => pinturaNavigate(imageUrl, image)}
                        component="img"
                        className="image_container"
                        src={!imageThumbnailUrl.includes("undefined") ? imageThumbnailUrl : imageUrl}
                        alt={`Image ${index + 1}`}
                      />
                      {renderTextField(index, image)}
                    </Grid>
                  ) : image.online === "offline" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}>
                      <div style={{ display: "none" }}>
                        {image.filetype === "image"
                          ? media.push({
                              type: "image",
                              fileUrl: image.onlineUrl,
                            })
                          : media.push({
                              type: "video",
                              fileUrl: image.onlineUrl,
                            })}
                      </div>
                      <Box className="video_container">
                        <Box
                          component="img"
                          className="image_container"
                          src={!videoThumbnail?.includes("undefined") ? videoThumbnail : noImageFound}
                          alt={`Image ${index + 1}`}
                          onClick={() => {
                            setVideoEditor(true);
                            SetVideoId(image?.id);
                            setEditVideoObject(image);
                            setVideoLink(videoUrl);
                          }}
                        />
                        {!videoThumbnail?.includes("undefined") ? (
                          <PlayCircleFilledIcon
                            className="video_play_icon"
                            onClick={() => {
                              setVideoEditor(true);
                              SetVideoId(image?.id);
                              setEditVideoObject(image);
                              setVideoLink(videoUrl);
                            }}
                          />
                        ) : (
                          <CircularProgress className="circular_progress_icon" />
                        )}
                        {renderTextField(index, image)}
                      </Box>
                    </Grid>
                  )
                ) : (
                  ""
                );
              })
            : ""}
        </>
      )}
      {videoEditor && (
        <ImageCarousel
          inspectionResponse={inspectionResponse}
          videolink={videolink}
          mediaData={mediaData?.filter((item) => item.filetype.includes("video"))}
          videoEditor={videoEditor}
          setVideoEditor={setVideoEditor}
          videoId={videoId}
        />
      )}
    </>
  );
};

export default PdfShowMore;
