import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CssBaseline, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import "../../../../Assets/Styles/Containers/_commentLibraryModal.scss";
import { toast } from "react-hot-toast";
// tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import parse from "html-react-parser";
// library
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import Divider from "@mui/material/Divider";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import PublicIcon from "@mui/icons-material/Public";
import { useDispatch } from "react-redux";
import { addNewCommentNarrative, addSelectedCommentesFromCl, getCommentLibraryList, getCommentLibraryTagList, getCommentLibrarySummaryTagList, updateDropDownValues } from "../../../Helpers/ApiCall";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { descendingComparator, filterSelectTag, preprocessHTMLContent, stableSort } from "./CommentLibraryHelper";
import axios from "axios";
import { origin } from "../../../Config/Api-constants";
import { Base64 } from "js-base64";
import CLAddCommentModal from "./CLAddCommentModal";
import { useLocation } from "react-router-dom";
import { setShowCommentLibraryModal } from "../../../Redux/Actions/CommonActions";
import CommentTags from "./CommentTags";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//   library table
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  // rowsPerPage: PropTypes.number.isRequired,
};

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const CommentLibraryModal = ({ lineData, templateId, lineUuid, categoryUuid, existingCommentUuids, categoryName, templateGuid, categoryId }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const showClDropdown = storeData?.inspectionData?.data?.show_cl_dropdown;
  const rowsPerPage = 10;
  const [value, setValue] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [commentListData, setCommentListData] = useState([]);
  const [initialTableData, setInitialTableData] = useState([]);
  const [search, setSearch] = useState(false);
  const [commentTitle, setCommentTitle] = useState("");
  const [commentTag, setCommentTag] = useState([]);
  const [commentSummaryTag, setCommentSummaryTag] = useState([]);
  const [addToLibrary, setAddToLibrary] = useState(false);
  const [commentText, setCommentText] = useState({ innerHTML: "", dropdownList: [], selected_value: "" });
  const [commentSummary, setCommentSummary] = useState({ innerHTML: "", dropdownList: [], selected_value: "" });
  const location = useLocation();
  const categoryNameFromLocation = location?.state?.category_name;
  const showCommentLibraryModal = storeData?.showCommentLibraryModal;
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} className="cl-table-head">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => (headCell.id !== "name" && headCell.id !== "global" ? <UnfoldMoreIcon className="cl-sort-icon" /> : "")}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: <LastPageIcon className="cl-table-exp-icon" onClick={toggleAllRowsExpansion} />,
    },
    {
      id: "title",
      numeric: false,
      disablePadding: false,
      label: "Title",
    },
    {
      id: "comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
    },
    {
      id: "summary",
      numeric: false,
      disablePadding: false,
      label: "Summary",
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      label: "Summary Section",
    },
    {
      id: "global",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevExpandedRows) => (prevExpandedRows.includes(rowId) ? prevExpandedRows.filter((id) => id !== rowId) : [...prevExpandedRows, rowId]));
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  function toggleAllRowsExpansion() {
    const allRowIds = commentListData?.map((row) => row.id);
    setExpandedRows((prevExpandedRows) => (prevExpandedRows.length === allRowIds.length ? [] : allRowIds));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    dispatch(setShowCommentLibraryModal(false));
    setSelected([]);
    setCommentTitle("");
    setCommentText({ innerHTML: "", dropdownList: [], selected_value: "" });
    setCommentSummary({ innerHTML: "", dropdownList: [], selected_value: "" });
    setCommentTag([]);
    setCommentSummaryTag([]);
    setExpandedRows([]);
    setSearch(false);
    setPage(0);
    setAddToLibrary(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedComment = commentListData?.map((n) => n.id);
      setSelected(selectedComment);
      return;
    }
    setSelected([]);
  };

  const handleClickChangeDropdown = (event, id, row, action) => {
    !isRowExpanded(id) && toggleRowExpansion(row.id);
    const selectedIndex = selected.indexOf(row.uuid);
    if (action === "dropdown") {
      if (!isSelected(row.uuid)) {
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, row.uuid);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
      }
    }
  };

  const getClListForSearch = async (totalComments) => {
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.get(`${baseUrl}get-comment-library-list?page_count=${totalComments}&category_uuid=${categoryUuid}&template_guid=${templateGuid}&category_id=${categoryId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
          "api-key": `${Base64.decode(sessionAPIKey)}`,
          platform: "web",
        },
      });
      setInitialTableData(response?.data?.data?.data);
    } catch (error) {
      console.log("response error", error);
    }
  };
  const handleSearchFilter = (event) => {
    setPage(0);
    const query = event.toLowerCase();
    if (query === "") {
      setSearch(false);
      setCommentListData(storeData?.getClListStatus ? storeData?.commentLibraryListData?.data : []);
    } else {
      setSearch(true);
      const searchData = initialTableData?.filter((item) => {
        return item?.comment_text?.toLowerCase()?.includes(query) || item?.title?.toLowerCase()?.includes(query);
      });
      setCommentListData(searchData);
    }
  };

  const handleClick = (event, id, row) => {
    !isRowExpanded(id) && toggleRowExpansion(row.id);
    const selectedIndex = selected.indexOf(row.uuid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCommentLibraryList(dispatch, {
      page: newPage + 1,
      category_uuid: categoryUuid,
      template_guid: templateGuid,
      category_id: categoryId,
    });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commentListData?.length) : 0;

  const visibleRows = useMemo(() => stableSort(commentListData, getComparator(order, orderBy)), [order, orderBy, page, rowsPerPage, commentListData]);

  const handleAddSelectedComment = () => {
    let payload = {
      comment_uuid: selected,
      inspection_id: storeData?.inspectionData?.data?.inspection_id,
      template_id: templateId,
      category_uuid: categoryUuid,
      line_uuid: lineUuid,
    };
    addSelectedCommentesFromCl(dispatch, payload);
    handleClose();
  };

  const replacePlaceholdersWithDropdowns = (htmlString, dropdowns, type, commentId, commentUUId, rowDisabled, isTruncate, maxLength) => {
    let newHtmlString = isTruncate ? htmlString?.slice(0, maxLength) : htmlString;
    const newMaxLength = maxLength + 50;
    if (isTruncate) {
      const incompleteDropdownRegex = /\[dropdown [a-f0-9\-]+/g;
      const incompleteDropdownMatch = newHtmlString?.match(incompleteDropdownRegex);
      if (incompleteDropdownMatch && incompleteDropdownMatch.length > 0) {
        newHtmlString = htmlString?.slice(0, newMaxLength);
        const newDropdownMatch = newHtmlString?.match(incompleteDropdownRegex);
        if (newDropdownMatch && newDropdownMatch.length > 1) {
          const dropdownIndex = newHtmlString?.indexOf(newDropdownMatch[1]);
          const newTruncatedString = newHtmlString?.slice(0, dropdownIndex);
          newHtmlString = newTruncatedString;
        }
      }
    }
    const regexSpecialSymbol = /Ã|Â|ÃÂ/g;
    function decodeEntities(encodedString) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = encodedString;
      return textarea.value;
    }
    if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
      dropdowns?.forEach((dropdown) => {
        const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
        const regex = new RegExp(placeholder, "g");
        const selectElement = document.createElement("select");
        if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
          let newDropdownValues = dropdown?.dropdowns_values?.values;
          newDropdownValues = newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXX"));
          if (!newDropdownValues?.find((value) => value?.includes("-----"))) {
            newDropdownValues?.unshift("-------------");
          }
          newDropdownValues?.forEach((value) => {
            const option = document.createElement("option");
            if (value?.length > 40) option.text = `${value.substring(0, 40)}...`;
            else option.text = value;
            option.value = value;
            option.title = value;
            selectElement.appendChild(option);
            if (dropdown.selected_value === value) {
              option.setAttribute("selected", "selected");
            }
            selectElement.classList.add(`${type}-cl-dropdown`);
            selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
            selectElement.setAttribute("comment_id", commentId);
            selectElement.setAttribute("comment_uuid", commentUUId);
            selectElement.setAttribute("dropType", type);
            if (rowDisabled) {
              selectElement.setAttribute("disabled", "true");
            } else {
              selectElement.removeAttribute("disabled");
            }
          });
        } else if (Array?.isArray(dropdown?.dropdowns_values)) {
          let newDropdownValues = dropdown?.dropdowns_values;
          newDropdownValues = newDropdownValues?.filter((obj) => !obj?.toUpperCase()?.includes("XXX"));
          if (!newDropdownValues?.find((value) => value?.includes("-----"))) {
            newDropdownValues?.unshift("-------------");
          }
          newDropdownValues?.forEach((value) => {
            const option = document.createElement("option");
            if (value?.length > 40) option.text = `${value.substring(0, 40)}...`;
            else option.text = value;
            option.value = value;
            option.title = value;
            selectElement.appendChild(option);
            if (dropdown.selected_value === value) {
              option.setAttribute("selected", "selected");
            }
            selectElement.classList.add(`${type}-cl-dropdown`);
            selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
            selectElement.setAttribute("comment_id", commentId);
            selectElement.setAttribute("comment_uuid", commentUUId);
            selectElement.setAttribute("dropType", type);
            if (rowDisabled) {
              selectElement.setAttribute("disabled", "true");
            } else {
              selectElement.removeAttribute("disabled");
            }
          });
        }
        newHtmlString = newHtmlString?.replace(regex, selectElement?.outerHTML);
      });
    } else {
      const regexPlainDropdown = /\[dropdown [a-f0-9\-]+\]/g;
      newHtmlString = newHtmlString?.replace(regexPlainDropdown, (match, selectId) => {
        return `<select id="${selectId}" class="${type}-cl-dropdown"><option>--------------</option></select>`;
      });
    }
    if (isTruncate) {
      const parser = new DOMParser();
      const parsedDocument = parser.parseFromString(`${newHtmlString || ""}${newHtmlString ? "..." : ""}`, "text/html");
      const correctedHtml = parsedDocument.body.innerHTML;
      return parse(decodeEntities(correctedHtml?.replace(regexSpecialSymbol, "")));
    }
    return parse(decodeEntities(newHtmlString?.replace(regexSpecialSymbol, ""))) || "";
  };

  const handleChangeDropdown = (event, dropdownUuid, commentId, type, commentUUId, commentDisabled) => {
    commentDisabled === "false" && handleClickChangeDropdown(event, commentId, { id: commentId, uuid: commentUUId }, "dropdown");
    const selectedValue = event.target.value;
    const dropdownValuesArrays = commentListData.map((item) => {
      const commentDropdowns = item.comment_dropdowns || [];
      const summaryDropdowns = item.summary_comment_dropdowns || [];
      const allDropdowns = [...commentDropdowns, ...summaryDropdowns];
      return allDropdowns?.filter((dropdown) => dropdown?.dropdown_uuid === dropdownUuid)?.map((dropdown) => dropdown?.dropdowns_values?.values);
    });
    const dropdownValues = dropdownValuesArrays.flat().find((values) => values.length > 0);
    let payload = {
      comment_id: commentId,
      dropdown_uuid: dropdownUuid,
      dropdown_type: type === "comment" ? "1" : "2",
      selected_value: selectedValue,
      dropdown_values: dropdownValues,
    };
    updateDropDownValues(dispatch, payload);
  };

  setTimeout(() => {
    let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
    commentDropElements?.forEach((element) => {
      const commentId = element.getAttribute("comment_id");
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const commentDisabled = element.hasAttribute("disabled");
      if (element && !commentDisabled) {
        element.onchange = (event) => {
          handleChangeDropdown(event, element.id, commentId, dropType, commentUUId, commentDisabled);
        };
      }
    });
  }, 100);

  setTimeout(() => {
    let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
    summaryDropElements?.forEach((element) => {
      const commentId = element.getAttribute("comment_id");
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const commentDisabled = element.hasAttribute("disabled");
      if (element && !commentDisabled) {
        element.onchange = (event) => handleChangeDropdown(event, element.id, commentId, dropType, commentUUId, commentDisabled);
      }
    });
  }, 100);

  const handleAddNewComment = () => {
    let payload = {
      template_id: templateId,
      category_uuid: categoryUuid,
      line_uuid: lineUuid,
      title: commentTitle,
      tag_ids: commentTag?.filter((item) => item !== "")?.map((tag) => parseInt(tag.id)),
      summary_ids: commentSummaryTag?.filter((item) => item !== "")?.map((summaryTag) => parseInt(summaryTag.id)),
      comment: showClDropdown ? `${filterSelectTag(commentText?.innerHTML)}` : commentText?.innerHTML,
      summary: showClDropdown ? `${filterSelectTag(commentSummary?.innerHTML)}` : commentSummary?.innerHTML,
      comment_dropdowns: commentText?.dropdownList,
      summary_comment_dropdowns: commentSummary?.dropdownList,
      add_to_library: addToLibrary,
    };
    addNewCommentNarrative(dispatch, payload);
    handleClose();
  };

  useEffect(() => {
    if (!storeData?.getClListStatus) {
      toast.error(storeData?.commentLibraryListData?.data?.message);
    }
  }, [storeData?.commentLibraryListData, storeData?.getClListStatus]);

  useEffect(() => {
    if (showCommentLibraryModal) {
      getCommentLibraryList(dispatch, { page: 1, category_uuid: categoryUuid, template_guid: templateGuid, category_id: categoryId });
      getCommentLibraryTagList(dispatch);
      getCommentLibrarySummaryTagList(dispatch);
      setValue(0);
    }
  }, [showCommentLibraryModal]);

  useEffect(() => {
    if (storeData?.getClListStatus && storeData.commentLibraryListData) {
      setInitialTableData(storeData.commentLibraryListData.data);
      setCommentListData(storeData.commentLibraryListData.data);
    }
  }, [storeData.commentLibraryListData]);

  useEffect(() => {
    if (storeData.commentLibraryListData) getClListForSearch(storeData?.commentLibraryListData?.total);
  }, [storeData.commentLibraryListData]);

  const TruncatedText = (props) => {
    const { text, expanded, fieldType, commentDropdowns, textType, rowId, rowUuid, rowDisabled } = props;
    const maxLength = fieldType === "title" ? 20 : fieldType === "comment_text" ? 120 : 70;
    return (
      <div onClick={() => (rowDisabled ? undefined : !isRowExpanded(rowId) ? toggleRowExpansion(rowId) : undefined)}>
        {fieldType === "title" ? text : ""}
        {(expanded || text?.length <= maxLength) && fieldType !== "title"
          ? replacePlaceholdersWithDropdowns(text, commentDropdowns, textType, rowId, rowUuid, rowDisabled, false, maxLength)
          : fieldType !== "title" && replacePlaceholdersWithDropdowns(text, commentDropdowns, textType, rowId, rowUuid, rowDisabled, true, maxLength)}
      </div>
    );
  };

  return (
    <Dialog className="dialog-container-comment-library" open={showCommentLibraryModal} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description">
      <div className="close-wrapper-new-category-right">
        <CloseIcon className="checkbox_container" onClick={handleClose} />
      </div>
      <DialogTitle className="comment-library-dialog-title">
        <span>{categoryName || categoryNameFromLocation || ""}</span> - Add comment
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box className="lineDetails__tab cl_tabs" sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="line_details_tab">
                <Tab className={`tab__label cl_tab_panel ${value === 0 ? "active" : "inactive"}`} label="Comment Library" {...a11yProps(0)} />
                <Tab className={`tab__label cl_tab_panel captitalize ${value === 1 ? "active" : "inactive"}`} label={"New comment"} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Grid></Grid>
            <Box className="dialog-content-comment-library">
              {/* Comment library tab */}
              <TabPanel value={value} index={0} className="tab_panel_cLibrary">
                {storeData?.addCommentListLoader ? (
                  <Box className="loader-container-comment-library" sx={{ width: "100%" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Grid container className="cl-search-container">
                      <Grid item xs={12} md={9} className="cl-search-bar">
                        <div className="cl-search">
                          <TextField
                            id="search-bar"
                            onChange={(e) => {
                              handleSearchFilter(e.target.value);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#ccc", // Set your desired hover border color here
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#ccc", // Initial border color
                                },
                              },
                            }}
                            variant="outlined"
                            placeholder="Search"
                            size="small"
                            InputProps={{
                              style: { height: "36px", paddingRight: "30px" },
                              inputProps: { style: { height: "36px", padding: "0 14px" } },
                            }}
                            style={{ height: "36px", width: "100%" }}
                          />
                          <SearchIcon className="cl-search-icon" />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={1.5}></Grid>
                      <Grid item xs={12} md={1.5}></Grid>
                    </Grid>
                    <TableContainer>
                      <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
                        <EnhancedTableHead
                          numSelected={selected?.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={commentListData?.length}
                        />
                        <TableBody>
                          {visibleRows?.map((row, index) => {
                            const isItemSelected = isSelected(row.uuid);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            let rowDisabled = existingCommentUuids?.includes(row.uuid) ? true : false;
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.uuid}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                                className={`cl_row ${rowDisabled ? "disabled cl-row-disabled" : ""}`}
                              >
                                <TableCell
                                  padding="checkbox"
                                  onClick={(event) => (rowDisabled ? undefined : handleClick(event, row.id, row))}
                                  className={`cl-checkbox-col ${rowDisabled ? (isItemSelected ? "cl-check-active" : "") : isRowExpanded(row.id) ? (isItemSelected ? "cl-check-active" : "") : ""}`}
                                >
                                  <Checkbox
                                    sx={{
                                      height: "37.5px !important",
                                      width: "37.5px !important",
                                      "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                                    }}
                                    color="primary"
                                    checked={isItemSelected || rowDisabled}
                                    disabled={rowDisabled}
                                  />
                                </TableCell>
                                <TableCell id={labelId} scope="row" padding="none" className={`cl-row-status cl-exp-col ${rowDisabled ? "row_disabled" : ""}`}>
                                  <IconButton
                                    sx={{ height: "19.5px", width: "19.5px", "& .MuiSvgIcon-root": { fontSize: "19.5px !important" } }}
                                    onClick={() => (rowDisabled ? undefined : toggleRowExpansion(row.id))}
                                  >
                                    {rowDisabled ? <ExpandLessIcon className="disabled_collapse_cl_icon" /> : isRowExpanded(row.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                  </IconButton>
                                </TableCell>
                                <TableCell className={`cl-title-col ${rowDisabled ? "row_disabled" : ""}`} align="left">
                                  <TruncatedText text={row.title} expanded={isRowExpanded(row.id)} fieldType={"title"} />
                                </TableCell>
                                <TableCell className={`cl-comment-col ${rowDisabled ? "row_disabled" : ""}`} align="left">
                                  <TruncatedText
                                    text={row.comment_text?.replace(/<\/?[^>]+(>|$)/g, "")}
                                    expanded={isRowExpanded(row.id)}
                                    fieldType={"comment_text"}
                                    commentDropdowns={row?.comment_dropdowns}
                                    textType={"comment"}
                                    rowId={row?.id}
                                    rowUuid={row?.uuid}
                                    rowDisabled={rowDisabled}
                                  />
                                  <CommentTags tags={row?.tags} />
                                </TableCell>
                                <TableCell className={`cl-summary-col ${rowDisabled ? "row_disabled" : ""}`} align="left">
                                  <TruncatedText
                                    text={row.summary_comment_text?.replace(/<\/?[^>]+(>|$)/g, "")}
                                    expanded={isRowExpanded(row.id)}
                                    fieldType={"summary_text"}
                                    commentDropdowns={row?.summary_comment_dropdowns}
                                    textType={"summary"}
                                    rowId={row?.id}
                                    rowUuid={row?.uuid}
                                    rowDisabled={rowDisabled}
                                  />
                                </TableCell>
                                <TableCell className={`cl-rating-col ${rowDisabled ? "row_disabled" : ""}`} align="left">
                                  {row?.summaries?.length !== 0
                                    ? row?.summaries?.map((element) => (
                                        <div className="cl_rating_square_container">
                                          <div
                                            className="rating_square"
                                            style={{
                                              background: `${element?.summary?.color}`,
                                              padding: "4px",
                                            }}
                                          ></div>
                                          <div className="cl_rating_name_text">{element?.summary?.name}</div>
                                        </div>
                                      ))
                                    : ""}
                                </TableCell>
                                <TableCell className={`cl-global-col ${rowDisabled ? "row_disabled" : ""}`} align="left">
                                  <div>
                                    <PublicIcon />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      component="div"
                      count={search ? commentListData?.length : storeData?.totalListCount}
                      rowsPerPage={search ? commentListData?.length : rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                    {/* </Paper> */}
                  </Box>
                )}
              </TabPanel>
              {/* New comments tab */}

              <TabPanel value={value} index={1} className="tab_panel_cLibrary">
                <CLAddCommentModal
                  commentTitle={commentTitle}
                  setCommentTitle={setCommentTitle}
                  commentSummaryTag={commentSummaryTag}
                  setCommentSummaryTag={setCommentSummaryTag}
                  commentTag={commentTag}
                  setCommentTag={setCommentTag}
                  commentText={commentText}
                  setCommentText={setCommentText}
                  commentSummary={commentSummary}
                  setCommentSummary={setCommentSummary}
                />
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className="comment-library-action">
        <div className={`comment-library-checkbox-container ${value === 0 ? "hidden" : "visible"}`}>
          {/* <FormGroup>
            <FormControlLabel className="cl_checkbox" control={<Checkbox />} label="Push to template" />
          </FormGroup> */}
          {value === 1 ? (
            <FormGroup>
              <FormControlLabel
                className="cl_checkbox"
                control={
                  <Checkbox
                    checked={addToLibrary}
                    onChange={() => setAddToLibrary(!addToLibrary)}
                    sx={{
                      height: "24px !important",
                      width: "24px !important",
                      marginRight: "8px",
                      "& .MuiSvgIcon-root": { height: "24px !important", width: "24px !important" },
                    }}
                  />
                }
                label="Save to comment  library"
              />
            </FormGroup>
          ) : null}
        </div>
        <div className="comment-library-action-btn">
          <Button className="cl-cancel-btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              value === 1
                ? commentTag.length === 0 ||
                  (commentTitle?.trim() === "" && (commentText?.innerHTML?.trim() === "" || commentText?.innerHTML?.trim() === "<p><br></p>" || preprocessHTMLContent(commentText?.innerHTML) === ""))
                : selected?.length !== 0
                ? false
                : true
            }
            className="cl-add-btn"
            onClick={value === 1 ? handleAddNewComment : selected?.length !== 0 ? handleAddSelectedComment : undefined}
          >
            {value === 1 ? "Add new comment" : `Add ${selected?.length} comments`}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CommentLibraryModal;
