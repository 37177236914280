import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid, MenuItem, Select } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import '../../../Assets/Styles/Containers/_lines.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { v4 as uuidv4 } from 'uuid';
import { UpdateTemplateDataSpecific } from '../../Helpers/ApiCall';
import { filterObjectsUptoCategory } from '../../Helpers/HelperFunctions';

const AddLineModal = ({showInspLineModal,setShowInspLineModal,categoryId,templateId,isInspDetails}) => {
   // redux
   const dispatch = useDispatch();
   const responseData = useSelector((state) => state.CommonReducer);
   const [selectedLineType, setSelectedLineType] = useState("0");
   const [newLineName,setNewLineName] = useState('');
   const [descriptionError,setDescriptionError]=useState(false)
   const [selectedLineTypeError,setSelectedLineTypeError]=useState(false)
   const [descriptionErrorText,setDescriptionErrorText]=useState("")
   const [selectedLineTypeErrorText,setSelectedLineTypeErrorText]=useState("")
   
    const handleClose=()=>{
        setShowInspLineModal(false);
        setNewLineName('')
        setSelectedLineType("0")
        setDescriptionError(false)
        setSelectedLineTypeError(false)
        setDescriptionErrorText("")
        setSelectedLineTypeErrorText("")
    }

    const validation=()=>{
      if(newLineName.trim() === ""){
        setDescriptionError(true)
        setDescriptionErrorText("Please fill the field")
      }else{ setDescriptionError(false) }
       if(selectedLineType === "0"){
        setSelectedLineTypeError(true)
        setSelectedLineTypeErrorText("Please select an answer format.")
      }
      else{
        setSelectedLineTypeError(false)
      }
      if(newLineName.trim()!=="" && selectedLineType !== "0" ){
        createNewLine()
      } 
    }
    const lineValidation=()=>{
      if(newLineName.trim() === ""){
        setDescriptionError(true)
        setDescriptionErrorText("Please fill the field")
      }else{
        setDescriptionError(false)
      }
      if(newLineName.trim()!==""){
        createNewLine()
      } 
    }

    const createNewLine=async()=>{
      let templateIndex = responseData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id == templateId);
      let categoryIndex = responseData?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id == categoryId);
      let prevData = JSON.parse(JSON.stringify(responseData?.inspectionData?.data));
      delete prevData?.report_media_library_files;
      let newLine;
      if(isInspDetails){
        newLine = {
          uuid: uuidv4(),
          prompt: newLineName,
          type: selectedLineType === "Checkbox"? "CheckBox":selectedLineType,
          defaultRating_id: 1,
          default_location: "",
          order: prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.length,
          is_deleted: 0,
          line_input_controls: null,
          comments: [],
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
        };
        if (selectedLineType === "Checkbox") {
          newLine.line_input_controls = [
            {
              data: [],
              type: "CheckBox",
              uuid: uuidv4(),
              prompt: newLineName,
              values: [],
              created_at: await GetUTCTime(),
              updated_at: await GetUTCTime(),
              line_input_control_id: uuidv4(),
            },
          ];
        } else if (selectedLineType === "TextBox") {
          newLine.line_input_controls = [
            {
              data: [],
              type: "TextBox",
              uuid: uuidv4(),
              prompt: newLineName,
              values: [],
              created_at: await GetUTCTime(),
              updated_at: await GetUTCTime(),
              line_input_control_id: uuidv4(),
            },
          ];
        } else {
          newLine.line_input_controls = [
            {
              data: { value: null, type:null },
              type: "Temperature",
              uuid: uuidv4(),
              prompt: newLineName,
              values: [],
              created_at: await GetUTCTime(),
              updated_at: await GetUTCTime(),
              line_input_control_id: uuidv4(),
            },
          ];
        }
      }else{
        newLine = {
          uuid: uuidv4(),
          prompt: newLineName,
          defaultRating_id: 1,
          default_location: "",
          order: prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.length,
          is_deleted: 0,
          line_input_controls: [],
          comments: [],
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
        };
      }
    
      if (prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines !==null) {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines.push(newLine);
      } else {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines = [newLine];
      }
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines?.map(async(item,index)=>{
        item.order = index+1;
        item.updated_at = await GetUTCTime();
        return item
      })
      let filteredObject = filterObjectsUptoCategory(prevData, templateIndex, categoryIndex);
      UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
      setNewLineName('')
      setSelectedLineType("0")
      setShowInspLineModal(false);
      setDescriptionError(false)
      setDescriptionErrorText("")
      setSelectedLineTypeError(false)
      setDescriptionErrorText("")
    }
    

    const handleChange = (e) => {
      const newName = e.target.value;
      setNewLineName(newName);
    }

  return (
    <Dialog className='dialog-container-new-category'
    open={showInspLineModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-line">
      <CloseIcon onClick={handleClose} />
    </div>
    <DialogTitle className='new-category-dialog-title'>{isInspDetails?"New line":"Add line"}</DialogTitle>
   
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="LineName"
              >
                Line name
              </label>
              <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                onChange={handleChange}
                sx={{ marginBottom: "15px" }}
                value={newLineName}
                name="categoryName"
                // error={false}
                // helperText={''}
                error={descriptionError}
                // helperText={descriptionErrorText}
                helperText={`${descriptionError ? descriptionErrorText : ""}`}
                // placeholder='[ex: name]'
                placeholder="Name"
              />
              {isInspDetails ?
              <>
              <label
                className="input-form-label"
                id="LineName"
              >
               Answer format
              </label>
              <Select id="legacy_user" size="small" variant="outlined"
                type="select"
                fullWidth
                onChange={(e)=>setSelectedLineType(e.target.value)}
                value={selectedLineType}
                name="categoryName"
                error={selectedLineTypeError}
                // helperText={selectedLineTypeErrorText}
              >
               
                <MenuItem sx={{display:"none"}} value="0">Select Answer Format</MenuItem>
                <MenuItem value="TextBox">Text Input</MenuItem>
                <MenuItem value="Checkbox">Checkbox</MenuItem>
                <MenuItem value="Temperature">Temperature</MenuItem>
              </Select>
              {<span style={{color:"#d32f2f",fontSize:"0.75rem",paddingLeft:"12px",paddingTop:"4px  "}}>{`${selectedLineTypeError ? selectedLineTypeErrorText : ""}`}</span>}
              </>
              
            :
            ""
              }
            </FormControl>

            {/* <FormControlLabel className='add_new_category_checkbox' control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Save to template" /> */}
        </Grid>
        {/* <Grid item xs={12} style={{marginTop:'10px'}}>
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="categoryName"
              >
                Description
              </label>
              <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                type="text"
               
                name="Description"
                error={false}
                helperText={''}
              />
            </FormControl>
        </Grid> */}

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>isInspDetails ? validation(): lineValidation()}
       >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default AddLineModal