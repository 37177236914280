import { Checkbox, Divider, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { stringify } from "json5";
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function CheckBoxElement({ element, templateId, elementType, pdfCategoriesType, pdfCategoryData, level }) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [apiCallDone, setApiCallDone] = useState(false);
  const [commentSelected, setCommentSelected] = useState(0);
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data));
  delete prevData?.report_media_library_files;   
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);
  let MediaPrevData = JSON?.parse(JSON?.stringify(storeData?.inspectionData?.data));
  let MediaElementPdfData = MediaPrevData?.inspectiontemplates[templateIndex];

  const handleCheckboxChange = () => {
    setCommentSelected((prevValue) => (prevValue == 0 ? 1 : 0));
    HandleUpdateCheckBoxApiCall(commentSelected == 0 ? 1 : 0);
  };

  useEffect(() => {
    let value = ElementpdfData?.[0]?.value;
    setCommentSelected(value == undefined || value == null ? "0" : value);
  }, [inspectionResponse, pdfCategoryData]);

  const HandleUpdateCheckBoxApiCall = async (val) => {
    let formData = {
      type: "CheckBox",
      merge_id: mergeId,
      value: stringify(val),
      updated_at: await GetUTCTime(),
    };
    pdfTemplate?.pdf_data?.push(formData);
    let filteredData = MediaElementPdfData?.pdf_data?.filter((el) => el?.merge_id != mergeId);
    filteredData.push(formData);
    let filteredObject = filterPdfTemplateData(prevData, templateIndex, filteredData, mergeId);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setApiCallDone(true);
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, []);

  return (
    <>
      <Grid container className={`${elementType === "outer" ? "pdf__container" : "pdf_inner__container"}`}>
        <Grid item xs={12}>
          <Grid className={`comment_detail_title ${level == 3 ? "level3_checkBoxclass" : ""}`}>
            <span className={`comment_title ${Number(commentSelected) ? "box_checked" : "box_unchecked"}`}>
              <FormGroup>
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      sx={{
                        height: "19.5px !important",
                        width: "19.5px !important",
                        "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                      }}
                      checked={Number(commentSelected)}
                      onClick={() => {
                        handleCheckboxChange();
                      }}
                    />
                  }
                />
              </FormGroup>
              <span
                // className="comment_title_single"
                className={
                  pdfCategoriesType == 1
                    ? (elementType !== "inner" && elementType !== "layout") || commentSelected == 0
                      ? "comment_title_single checkbox_label"
                      : "comment_title_single_after_selecting checkbox_label"
                    : "comment_title_single checkbox_label"
                }
                onClick={() => {
                  handleCheckboxChange();
                }}
              >
                {element?.label}
              </span>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CheckBoxElement;
