import { Grid } from "@mui/material";
import React from "react";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { Category } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import FlagIcon from '@mui/icons-material/Flag';
import { useEffect } from "react";
import { separtesummeryCounter, summeryCounter } from "../../Helpers/HelperFunctions";

function SummaryItemsReview({ inspectionResponse, inspectionData, }) {
  const navigate = useNavigate();

  return (
    <Grid container className="coll_inner_div">
      <div className="coll_div right_padding">
        {inspectionResponse?.filter((pdf) => pdf?.is_pdf_template !== 1)?.map((template) => <Grid container className="coll_inner_div" id={template?.template_id}>
          <span className="incomplete_temp">{template.name}</span>
          <div className="coll_div right_padding">
            <div className="card_wrapper">
              {template?.is_narrative_template !== 1 && template?.rating_data?.filter((item) => item?.include_in_summary == 1)?.map((rating) => (
                <>
                  <div className="coll_heading">
                    {JSON?.parse(separtesummeryCounter(template?.rating_data, template)).map((el) => el.name == rating.Name && el?.count)}  {rating.Name}
                    <div className="div_header_rating rating_square_container"><span className="rating_square" style={{ background: `${rating.Color}` }}>{rating.include_in_summary == "1" && <FlagIcon />}</span></div>
                  </div>
                  {template?.categories?.map((category) => {
                    if (category?.prompt == "Inspection Details") return;
                    return category?.lines?.map((lines) => (
                      <>
                        {lines?.comments?.map((comment) =>
                          comment?.comment_input_controls
                            ?.filter((item) => item?.type === "Rating")[0]
                            ?.data?.map((el) =>
                              el?.Name === rating?.Name && comment?.is_selected ? (
                                <>
                                  <div className="coll_content">
                                    <div>
                                      <p
                                        className="coll_content_text"
                                        onClick={() => {
                                          navigate(
                                            `/inspection/${category?.prompt !== null
                                              ? encodeURIComponent(
                                                category.prompt
                                              )
                                              : "Untitled"
                                            }/${lines.prompt !== null
                                              ? encodeURIComponent(lines.prompt)
                                              : "Untitled"
                                            }`,
                                            {
                                              state: {
                                                line: lines,
                                                template_id: category?.template_id,
                                                category_id: category?.id,
                                                category_uuid: category?.uuid,
                                                line_uuid: lines?.uuid,
                                                target: "comment",
                                              },
                                            }
                                          );
                                          //   activateLine(lines?.uuid);
                                        }}
                                      >
                                        {category.prompt === null
                                          ? "Untitled"
                                          : category.prompt}
                                        :{" "}
                                        {lines.prompt === null
                                          ? "Untitled"
                                          : lines.prompt}
                                      </p>
                                      <p
                                        className="coll_content_text"
                                        onClick={() => {
                                          navigate(
                                            `/inspection/${category?.prompt !== null
                                              ? encodeURIComponent(
                                                category.prompt
                                              )
                                              : "Untitled"
                                            }/${lines.prompt !== null
                                              ? encodeURIComponent(lines.prompt)
                                              : "Untitled"
                                            }`,
                                            {
                                              state: {
                                                line: lines,
                                                template_id: category?.template_id,
                                                category_id: category?.id,
                                                category_uuid: category?.uuid,
                                                line_uuid: lines?.uuid,
                                                target: "comment",
                                              },
                                            }
                                          );
                                          //   activateLine(lines?.uuid);
                                        }}
                                      >
                                        Comment:{" "}
                                        {(comment?.recommendation === null ||
                                          comment?.recommendation?.replace(
                                            /<\/?[^>]+(>|$)/g,
                                            ""
                                          ) === "") &&
                                          (comment?.prompt === null ||
                                            comment?.prompt === "")
                                          ? "Untitled"
                                          : comment?.prompt === null ||
                                            comment?.prompt === ""
                                            ? parse(
                                              comment?.recommendation?.replace(
                                                /<\/?[^>]+(>|$)/g,
                                                ""
                                              )
                                            )
                                            : comment.prompt}
                                      </p>
                                      <div
                                        className="review_image_container"
                                        style={{
                                          display: "flex !important",
                                          flexFlow: "wrap !important",
                                        }}
                                      >
                                        {comment?.comment_input_controls?.map(
                                          (commentData) => {
                                            if (commentData?.type == "Media"&& commentData?.is_disabled !==1)
                                              return commentData?.data?.filter(media => media?.is_deleted === 0)?.map(
                                                (media) => (
                                                  <div className="video_thumb_container">
                                                    {media.filetype === "image" ? (
                                                      ""
                                                    ) : (
                                                      <PlayCircleIcon className="comment_play_icon" />
                                                    )}
                                                    <img
                                                      src={
                                                        media.filetype === "image"
                                                          ? `${inspectionData?.bucket_url}${media?.onlineUrl}`
                                                          : `${inspectionData?.bucket_url}${media?.thumbnail}`
                                                      }
                                                      onClick={() => {
                                                        navigate(
                                                          `/inspection/${category?.prompt !==
                                                            null
                                                            ? encodeURIComponent(
                                                              category.prompt
                                                            )
                                                            : "Untitled"
                                                          }/${lines.prompt !== null
                                                            ? encodeURIComponent(
                                                              lines.prompt
                                                            )
                                                            : "Untitled"
                                                          }`,
                                                          {
                                                            state: {
                                                              line: lines,
                                                              template_id:
                                                                category?.template_id,
                                                              category_id:
                                                                category?.id,
                                                              category_uuid:
                                                                category?.uuid,
                                                              line_uuid:
                                                                lines?.uuid,
                                                              target: "comment",
                                                            },
                                                          }
                                                        );
                                                        // activateLine(lines?.uuid);
                                                      }}
                                                    />
                                                  </div>
                                                )
                                              );
                                          }
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <CallMissedOutgoingIcon
                                        className="updrop_icon"
                                        onClick={() => {
                                          navigate(
                                            `/inspection/${category?.prompt !== null
                                              ? encodeURIComponent(
                                                category.prompt
                                              )
                                              : "Untitled"
                                            }/${lines.prompt !== null
                                              ? encodeURIComponent(lines.prompt)
                                              : "Untitled"
                                            }`,
                                            {
                                              state: {
                                                line: lines,
                                                template_id: category?.template_id,
                                                category_id: category?.id,
                                                category_uuid: category?.uuid,
                                                line_uuid: lines?.uuid,
                                                target: "comment",
                                              },
                                            }
                                          );
                                          //   activateLine(lines?.uuid);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )
                            )
                        )}
                      </>
                    ));
                  })
                  }
                </>
              ))}
            </div>
          </div>
        </Grid>
        )
        }
      </div>
    </Grid>
  );
}

export default SummaryItemsReview;
