import { Grid, Collapse, Popper, Fade, Paper, ClickAwayListener, Typography, MenuItem, IconButton, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from "react";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import NarrativeMedia from "../Media/NarrativeMedia";
import NarrativeSummary from "./NarrativeSummary";
import UndoIcon from "@mui/icons-material/Undo";
import DeleteIcon from "@mui/icons-material/Delete";
import { replacePlaceholdersWithDropdowns } from "./CommentLibraryHelper";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import EditClCommentModal from "./EditClCommentModal";
import DeleteConfirmationDialog from "../../../Common/Alert/DeleteConfirmationDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteCommentDialog from "../../../Common/Alert/DeleteCommentDialog";

const CommentDetailsNarrative = (props) => {
  let {
    toggle,
    selectedMediaFiles,
    setSelectedImages,
    setSelectedMediaFiles,
    comment_text,
    uuid,
    comment_dropdowns = [],
    summaries,
    summary_comment_text,
    summary_comment_dropdowns = [],
    title,
    templateId,
    categoryId,
    categoryUuid,
    lineUuid,
    commentIndex,
    is_selected,
    lineData,
    comment_input_controls,
    isNarrative,
    addCommentFromLine,
    tags,
    categoryName,
    setLineData,
    is_expanded,
  } = props;
  const [commentSelected, setCommentSelected] = useState(true);
  const [inspectionData, setInspectionData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const [showEditCLModal, setShowEditCLModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const openPopper = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const checkComment = async (e, status) => {
    e.stopPropagation();
    setCommentSelected(status);
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((item) => item.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((item) => item.uuid == uuid);
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_expanded = status ? 1 : 0;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  let commentDropElements = document.querySelectorAll(".comment-cl-detail-dropdown");
  commentDropElements?.forEach((element) => {
    if (element !== null && element !== undefined) {
      const commentUUId = element.getAttribute("comment_uuid");
      element.onclick = (event) => {
        event.stopPropagation();
      };
      element.onchange = (event) => handleChangeDropdown(event, element.id, commentUUId);
    }
  });
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleChangeDropdown = async (event, dropdownUuid, commentUUId) => {
    event.stopPropagation();
    const selectedValue = event.target.value;
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((item) => item.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((item) => item.uuid == commentUUId);
    let dropdownIndex = prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_dropdowns.findIndex(
      (dropdown) => dropdown.dropdown_uuid === dropdownUuid
    );
    if (dropdownIndex !== -1) {
      prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_dropdowns[dropdownIndex].selected_value = selectedValue;
    }
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  const deleteClComment = async () => {
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex((item) => item.template_id === templateId);
    let categoryIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    delete prevData?.report_media_library_files;
    let commentIndex = inspectionData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((item) => item.uuid == uuid);
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].updated_at = await GetUTCTime();
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].is_selected_updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    setLineData(prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex]);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionData(storeData.inspectionData?.data);
    }
  }, [storeData.inspectionData]);

  return (
    <>
      <EditClCommentModal
        lineData={lineData}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        setShowEditCLModal={setShowEditCLModal}
        showEditCLModal={showEditCLModal}
        title={title}
        comment_text={comment_text}
        uuid={uuid}
        comment_dropdowns={comment_dropdowns}
        summaries={summaries}
        summary_comment_text={summary_comment_text}
        summary_comment_dropdowns={summary_comment_dropdowns}
        categoryUuid={categoryUuid}
        commentIndex={commentIndex}
        is_selected={is_selected}
        tags={tags}
        inspectionData={inspectionData}
        categoryName={categoryName}
        setShowDeleteModal={setShowDeleteModal}
      />
      <DeleteCommentDialog
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        title={title ? `Delete '${title}'?` : "Delete?"}
        contentText={"Are you sure you want to delete this comment from the report?"}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteModal}
        submitBtnTitle={"Delete"}
        submitBtnAction={deleteClComment}
      />
      {/* <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div style={{ padding: "10px" }}>
                  <MenuItem
                    className="cl_popper_menu"
                    style={{ padding: "8px" }}
                    onClick={() => setShowEditCLModal(true)}
                  >
                    <EditIcon className="editClDescIcon" />
                    <Typography className="editClDescText">Edit</Typography>
                  </MenuItem>
                  <MenuItem
                    className="cl_popper_menu"
                    style={{ padding: "8px", cursor: "not-allowed" }}
                  >
                    <UndoIcon className="editClDescIcon" />
                    <Typography className="editClDescText">
                      Restore to original
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    className="cl_popper_menu"
                    style={{ padding: "8px" }}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    <DeleteIcon className="editClDescIcon" />
                    <Typography className="editClDescText">Delete</Typography>
                  </MenuItem>
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper> */}
      <Accordion className="comment_container" expanded={is_expanded}>
        <AccordionSummary sx={{ padding: "0px", marginX: "0px", marginLeft: "25px" }} onClick={(e) => checkComment(e, !is_expanded)}>
          <Grid container display={"flex"}>
            <Grid item xs={1} mt={-1.2} display={"flex"} alignItems={"start"} justifyContent={"flex-end"}>
              <IconButton sx={{ color: "#252525", fontSize: "16px" }} onClick={(e) => checkComment(e, !is_expanded)}>
                {is_expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Grid>
            <Grid item container xs={10} display={"flex"} alignItems={"start"}>
              {title !== null && (
                <Grid item xs={12} mb={1} display={"flex"} alignItems={"start"}>
                  <Typography className={is_expanded ? "narrative_comment_title_expends" : "narrative_comment_title"} id={`comment_title_${commentIndex}`}>
                    {title !== null ? title : null}
                  </Typography>
                </Grid>
              )}
              <Grid>
                <Grid item xs={12} className="comment_detail_title">
                  <span className={is_expanded ? "comment_detail_cl" : "comment_description_cl"} id={"comment_description_cl"}>
                    {comment_text !== null && replacePlaceholdersWithDropdowns(comment_text, comment_dropdowns, "comment", uuid)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item mt={-0.6} xs={1} display={"flex"} alignItems={"start"} justifyContent={"flex-end"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setShowEditCLModal(true);
                }}
                  sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <EditIcon className="editClDescIcon" />
              </IconButton>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setShowDeleteModal(true);
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <DeleteIcon className="editClDescIcon" />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: "0px !important", pl: storeData?.reportSideBarActive ? "16px !important" : isMobile ? "16px !important" : "42px !important" }}>
          <Grid container>
            <NarrativeSummary
              summary={summary_comment_text}
              summaries={summaries}
              templateId={templateId}
              categoryId={categoryId}
              lineUuid={lineUuid}
              commentUuid={uuid}
              commentIndex={commentIndex}
              summary_comment_dropdowns={summary_comment_dropdowns}
              inspectionData={inspectionData}
              uuid={uuid}
            />
          </Grid>
          <Grid container sx={{ mt: "17px" }}>
            <NarrativeMedia
              setSelectedImages={setSelectedImages}
              toggle={toggle}
              setSelectedMediaFiles={setSelectedMediaFiles}
              selectedMediaFiles={selectedMediaFiles}
              data={comment_input_controls?.find((data) => data.type === "Media")}
              title="Add Media"
              action="comment"
              templateId={templateId}
              categoryId={categoryId}
              lineUuid={lineUuid}
              commentUuid={uuid}
              commentIndex={commentIndex}
              categoryUuid={categoryUuid}
              setRefresh={setRefresh}
              isNarrative={isNarrative}
              addCommentFromLine={addCommentFromLine}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* <Grid
        container
        className="comment__container"
        sx={{ ...(commentSelected ? {} : { ":hover": { cursor: "pointer" } }) }}
      >
        <Grid item xs={12}>
          <Grid
            className="comment_detail_title"
            onClick={(e) => !commentSelected && checkComment(e, true)}
            sx={{
              ...(commentSelected ? {} : { ":hover": { cursor: "pointer" } }),
            }}
          >
            <span
              className="comment_title"
              id={`comment_${commentIndex}`}
              onClick={(e) => {
                commentSelected && checkComment(e, false);
              }}
            >
              <FormGroup id={`comment_checkbox_${commentIndex}`}>
                <FormControlLabel
                  className="checkbox"
                  control={<Checkbox checked={commentSelected} />}
                />
              </FormGroup>
              <span
                className="comment_title_single_cl"
                id={`comment_title_${commentIndex}`}
              >
                {title !== null && title}
              </span>
            </span>
            <IconButton onClick={() => setShowDeleteModal(true)}>
              <DeleteIcon className="editClDescIcon" />
            </IconButton>
            {
              <span
                className="cl_details_action"
                id={`edit_cl_comment_${commentIndex}`}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  handleClick("bottom-end")(event);
                }}
              >
                <EditIcon />
                Edit
              </span>
            }
          </Grid>
          <Grid>
            <Grid className="comment_detail_title">
              <span className="comment_description_cl">
                {comment_text !== null &&
                  replacePlaceholdersWithDropdowns(
                    comment_text,
                    comment_dropdowns,
                    "comment",
                    uuid
                  )}
              </span>
            </Grid>
          </Grid>

          <Collapse in={commentSelected}>
            <Grid container sx={{ mt: "17px" }}>
              <NarrativeSummary
                summary={summary_comment_text}
                summaries={summaries}
                templateId={templateId}
                categoryId={categoryId}
                lineUuid={lineUuid}
                commentUuid={uuid}
                commentIndex={commentIndex}
                summary_comment_dropdowns={summary_comment_dropdowns}
                inspectionData={inspectionData}
                uuid={uuid}
              />
            </Grid>
            <Grid container sx={{ mt: "17px" }}>
              <NarrativeMedia
                setSelectedImages={setSelectedImages}
                toggle={toggle}
                setSelectedMediaFiles={setSelectedMediaFiles}
                selectedMediaFiles={selectedMediaFiles}
                data={comment_input_controls?.find(
                  (data) => data.type === "Media"
                )}
                title="Add Media"
                action="comment"
                templateId={templateId}
                categoryId={categoryId}
                lineUuid={lineUuid}
                commentUuid={uuid}
                commentIndex={commentIndex}
                categoryUuid={categoryUuid}
                setRefresh={setRefresh}
                isNarrative={isNarrative}
                addCommentFromLine={addCommentFromLine}
              />
            </Grid>
          </Collapse>
        </Grid>
      </Grid> */}
    </>
  );
};

export default CommentDetailsNarrative;
