import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { UpdateTemplateDataSpecificWithLoader } from '../../Helpers/ApiCall';
import toast from 'react-hot-toast';
// import { updateInspectionDataCall } from '../../Redux/Actions/CommonActions';

const CreateNewCategoryModal = ({templateData,showCreateCategoryModal,setShowCreateCategoryModal,
  showCategoryModal,setShowCategoryModal,newCategoryList,setNewCategoryList,handleCategorySelection}) => {
    const dispatch = useDispatch();  
    const [categoryName,setCategoryName] = useState('');
    const [categoryError,setCategoryError] =useState(false) 
    const [newCatToast,setNewCatToast] =useState(false) 
    const [categoryErrorMessage,setCategoryErrorMessage] =useState('') 
    const responseData = useSelector((state) => state.CommonReducer);
    
    const handleClose=()=>{
        setShowCreateCategoryModal(false);
        setShowCategoryModal(true);
        setCategoryError(false);
        setCategoryErrorMessage('');
    }
   
    const createNewCategory=async(responseData,newCategoryName)=>{
      if(newCategoryName.trim() === ''){
         setCategoryError(true);
        setCategoryErrorMessage('Please fill the field')
      }
      else{
// let availiableCategories = newCategoryList?.map(cat=>cat.label);
      // if(availiableCategories.includes(newCategoryName)){
        // setCategoryError(true);
        // setCategoryErrorMessage('This category already present in the list')
      // }
      // else{
        // setCategoryError(false);
        // setCategoryErrorMessage('')
        // setNewCategoryList(prev=>{
        //   return [{prompt:newCategoryName},...prev]
        // })  
        // handleCategorySelection({prompt:newCategoryName})
        // setShowCreateCategoryModal(false);
        // setShowCategoryModal(true);
        // setCategoryName('')
      // }
      let indexValue = responseData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateData.template_id);
      // let newCategory = {
      //   category_id: null,
      //   uuid: new Date().getTime(),
      //   prompt: newCategoryName,
      //   introductory_text: null,
      //   order: 1,
      //   is_deleted: 0,
      //   lines: [],
      // };
      
      let newCategory = {
          id: null,
          uuid: uuidv4(),
          template_id: 15206,
          prompt: newCategoryName.trim(),
          introductory_text: null,
          lines: null,
          parent_uuid: null,
          order: responseData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories?.length,
          is_deleted: 0,
          optional: 0,
          is_inspection_details: 0,
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
          is_selected: false
          }
          let prevData = JSON.parse(JSON.stringify(responseData?.inspectionData?.data));
          delete prevData?.report_media_library_files;
          let filteredData = JSON.parse(JSON.stringify(prevData));
          let filteredTemplateObject = filteredData?.inspectiontemplates[indexValue];
          filteredTemplateObject.categories = [newCategory];
          filteredData.inspectiontemplates = [filteredTemplateObject];
          setNewCatToast(true)
          UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredData);
          setShowCreateCategoryModal(false);
          setShowCategoryModal(false);
          setCategoryName('')
          setCategoryError(false)
          setCategoryErrorMessage("")
          //       responseData?.inspectionData?.inspectiontemplates[indexValue]?.categories.push(newCategory)
          // console.log("responseData?.inspectionData",responseData?.inspectionData)
          //       dispatch(updateInspectionDataCall(responseData?.inspectionData));
      }
      
    }

    useEffect(()=>{
      if(responseData?.updatedInspectionData?.success === true && newCatToast){
        toast.success("The inspection has been updated successfully.")
        setNewCatToast(false)
      }
    },[createNewCategory])

  return (
    <Dialog className='dialog-container-new-category'
    open={showCreateCategoryModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-category">
      <ArrowBackIcon onClick={handleClose} />
    </div>
    <DialogTitle className='new-category-dialog-title'>{"Create new category"}</DialogTitle>
   
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
        <Grid item xs={12} >
        <FormControl variant="standard" className="forms-control w-100">
              <label
                className="input-form-label"
                id="categoryName"
              >
                Category name
              </label>
              <TextField id="legacy_user" className="input-textfield" size="small" variant="outlined"
                type="text"
                onChange={(e)=>setCategoryName(e.target.value)}
                value={categoryName}
                name="categoryName"
                error={categoryError}
                helperText={categoryErrorMessage}
              />
            </FormControl>

            {/* <FormControlLabel className='add_new_category_checkbox' control={<Checkbox defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}/>} label="Save to template" /> */}
        </Grid>

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" onClick={()=>createNewCategory(responseData,categoryName)} >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default CreateNewCategoryModal