import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  Checkbox,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { useSelector, useDispatch } from "react-redux";
import { UpdateTemplateDataSpecificWithLoader } from "../../../Helpers/ApiCall";
import "../../../../Assets/Styles/Containers/_tableElement.scss";
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

const TableElement = ({ element, templateId }) => {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [checkboxValues, setCheckboxValues] = useState({});
  const storeData = useSelector((state) => state.CommonReducer);
  const [refresh, setrefresh] = useState(false);
  const dispatch = useDispatch();
  const tableData = useMemo(() => {
    let result = {};
    for (var i = 0; i < element.elements.length; i++) {
      const row = element.elements[i]?.row;
      const col = element.elements[i]?.col;
      if (!result[row]) result[row] = [];
      result[row].push(element?.elements[i]);
    }

    const sortedRows = Object.values(result).map((row) => {
      return row.sort((a, b) => {
        if (a.someProperty === b.someProperty) {
          return 0;
        } else {
          return a.someProperty < b.someProperty ? -1 : 1;
        }
      });
    });
    const tableRow = sortedRows.map((row) => {
      const result = [];
      let current_data = {};

      for (let i = 0; i < row?.length; i++) {
        if (i === 0) {
          current_data = { ...row[i], colSpan: 1 };
        } else if (current_data?.title !== row[i]?.title) {
          result.push(current_data);
          current_data = { ...row[i], colSpan: 1 };
        } else if (current_data?.merge_id !== row[i]?.merge_id) {
          result.push(current_data);
          current_data = { ...row[i], colSpan: 1 };
        } else {
          current_data.colSpan += 1;
        }
      }
      result.push(current_data);
      return result;
    });
    return tableRow;
  }, [element.elements]);

  const handleTableCheckBox = async (e, mergeId, type) => {
    const newCheckboxValues = { ...checkboxValues };
    newCheckboxValues[mergeId] = e.target.checked;
    setCheckboxValues(newCheckboxValues);
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (itm) => itm.template_id === templateId
      );
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;   
    let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
    let formData = {
      type,
      merge_id: mergeId,
      value: e.target.checked ? "1" : "0",
      updated_at: await GetUTCTime(),
    };
    pdfTemplate.pdf_data = [formData];    
    let filteredData = pdfTemplate?.pdf_data?.filter(el=>el?.merge_id != mergeId)
    filteredData.push(formData)
    let filteredObject = filterPdfTemplateData(prevData, templateIndex,filteredData,mergeId);
    UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
  };

  const tableCheckValue = (mergeId, data) => {
    let PDFdata = data?.find((item) => item?.merge_id === mergeId);
    return PDFdata?.value === 1 ? true : false;
  };

  useEffect(() => {
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (itm) => itm.template_id === templateId
      );
    let prevData = inspectionResponse?.inspectionData?.data
    let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
    if (pdfTemplate?.pdf_data) {
      const initialCheckboxValues = {};
      for (let i = 0; i < element.elements.length; i++) {
        const mergeId = element.elements[i].merge_id;
        initialCheckboxValues[mergeId] = tableCheckValue(
          mergeId,
          pdfTemplate?.pdf_data
        );
      }
      setCheckboxValues(initialCheckboxValues);
    }
    if (!refresh) setrefresh(true);
  }, [element?.elements, refresh]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData]);

  return (
    <div>
      <TableContainer component={Paper} sx={{ padding: "13px 13px 13px 22px" }}>
        <Table>
          <TableBody>
            {tableData?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((col, colIndex) =>
                  col.type == 2 ? (
                    <TableCell
                      key={colIndex}
                      className="table_cell_border1"
                      colSpan={col?.colSpan}
                      align="center"
                    >
                      <Typography className="column_typo1">
                        {col?.title}
                      </Typography>
                    </TableCell>
                  ) : col?.type == 4 && col?.value == false ? (
                    <TableCell
                      className="table_cell_border4"
                      width={"190px"}
                      align="center"
                    >
                      <Checkbox
                        checked={checkboxValues[col.merge_id] || false}
                        onChange={(e) =>
                          handleTableCheckBox(e, col.merge_id, col.input_type)
                        }
                      />
                    </TableCell>
                  ) : col?.type == 0 ? (
                    <TableCell
                      sx={{
                        border: "0.5px solid #EAEAEA",
                        backgroundColor: "#F9F8F9",
                      }}
                    ></TableCell>
                  ) : col?.type == 1 ? (
                    <TableCell
                      className="table_cell_border3"
                      align="center"
                      width={"70px"}
                    >
                      <Badge
                        badgeContent={
                          <Tooltip
                            style={{ backgroundColorcolor: "white" }}
                            title={
                              <span
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "17.5px",
                                  width: "231px",
                                  height: "67px",
                                  borderRadius: "4px",
                                  fontWeight: "400",
                                }}
                              >
                                {col?.onHove}
                              </span>
                            }
                            arrow
                          >
                            <InfoIcon className="info_icon" />
                          </Tooltip>
                        }
                        className="badge_info"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography className="column_typo3">
                          {col?.title}
                        </Typography>
                      </Badge>
                    </TableCell>
                  ) : (
                    <TableCell className="table_cell_border2" width={"200px"}>
                      <Typography className="column_typo2">
                        {col?.title}
                      </Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableElement;
