import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import '../../../../Assets/Styles/Containers/_button.scss';
import '../../../../Assets/Styles/Containers/_inspection.scss';
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { UpdateTemplateDataSpecific } from '../../../Helpers/ApiCall';
import { GetUTCTime } from '../../../Helpers/GetUTCTime';
import CloseIcon from '@mui/icons-material/Close';
import { filterObjectsUptoLine } from '../../../Helpers/HelperFunctions';

const AddInformation = ({showInformationModal,setShowInformationModal,information,setInformation,templateId,categoryId,lineUuid,inputChanged,setInputChanged}) => {
    const [editorContent,setEditorContent] = useState('')
    const [inspectionResponse,setInspectionResponse] = useState()
    const [refresh,setRefresh] = useState(false);
    
    const storeData = useSelector((state) => state.CommonReducer);
    const dispatch = useDispatch();
    const handleClose=()=>{
        setInputChanged(false)
        setShowInformationModal(false);
        setEditorContent("")
  }
    const handleChange=(content)=>{
        setInputChanged(content !== editorContent)
        setEditorContent(content)
    }

    
    const fontSizeConverter = (val) => {
      if (val === "1") {
        return 10;
      } else if (val === "2") {
        return 13;
      } else if (val === "3") {
        return 16;
      } else if (val === "4") {
        return 18;
      } else if (val === "5") {
        return 24;
      } else if (val === "6") {
        return 32;
      } else if (val === "7") {
        return 48;
      } else return val;
    };

    function convertFontTagsToInlineStyles(htmlString) {
      let regex = /style="([^"]*)font-family:([^"]*)"/g;
      const regexhtml = /<br\s*\/?>|&nbsp;/g;
      // Replace <font> tags with inline styles
      var convertedString = htmlString
        ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Open Sans';\">$3</span>")
        ?.replace(regex, 'style="$1"')
        ?.replace(/<font([^>]*)>/gi, function (match, p1) {
          var attributes = p1?.split(/\s+/);
          var styles = "";
          // Convert font attributes to inline styles
          for (var i = 0; i < attributes?.length; i++) {
            var attribute = attributes[i]?.trim();
            var parts = attribute?.split("=");
            if (parts.length === 2) {
              var name = parts[0]?.toLowerCase();
              var value = parts[1]?.replace(/['"]/g, "");
              // eslint-disable-next-line
              switch (name) {
                case "color":
                  styles += "color: " + value + "; ";
                  break;
                case "size":
                  styles += "font-size: " + fontSizeConverter(value) + "px !important; ";
                  break;
              }
            }
          }
          return "<span style='" + styles + "'>";
        });
      convertedString = convertedString?.replace(/<\/font>/gi, "</span>");
    
      return convertedString;
    }

    useEffect(() => {
     if(storeData.inspectionData) {
      setInspectionResponse(storeData)
      setRefresh(prev=>!prev)
      const converText  = convertFontTagsToInlineStyles(information!==undefined && information?.length > 0 ? information.toString():'')
      setEditorContent(converText)
      // setEditorContent(information!==undefined && information?.length > 0 ? information:'')
    }
    }, [storeData.inspectionData,information,showInformationModal])
    //  useEffect(() => {
    //   setEditorContent(information)
    // }, [])
    const saveInformation=async ()=>{
    let templateIndex =  inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid) 
    let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Information")
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;
    if(linIpIdx !== -1){ 
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].lines[lineIndex].line_input_controls[
      linIpIdx
    ].data = [editorContent === ''?'<p><br></p>':editorContent];
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].lines[lineIndex].line_input_controls[
      linIpIdx
    ].updated_at = await GetUTCTime();
    }else{
      let informationObject = {
        line_input_control_id: null,
        uuid: uuidv4(),
        prompt: "Information",
        values: [
          {
            value: editorContent === ''?'<p><br></p>':editorContent,
          },
        ],
        type: "Information",
        data: [editorContent === ''?'<p><br></p>':editorContent],
        updated_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls.push(informationObject);
    }
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
    setInputChanged(false)
    setShowInformationModal(false)    
  }
   
    
  return (
    <Dialog className='dialog-container-new-option'
    open={showInformationModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
<div className="close-wrapper-category">
      <CloseIcon onClick={handleClose} />
    </div>
    <DialogTitle className='editor-dialog-title'>{"Information"}</DialogTitle>    
    <span className='category-dialog-subtitle'>Add extra information about the line.</span>
   
    <DialogContent className="dialog-content-new-option">
        <p className="information_text">Information</p>
      <Grid container className="input-controll">
      <Grid item xs={12} sm={12}>         
    <SunEditor 
    className="editor-class"
   setDefaultStyle="font-size: 14px;"
   height='100%'
   setOptions={{ 
   // buttonList:[['bold','italic', 'underline', 'list','fontColor','fontSize']]
   buttonList: [
     // default
     ['bold','italic', 'underline'],['list'],['fontColor'],['fontSize'],    
     // (min-width: 992)
     ['%992', [
        ['bold','italic', 'underline'],['list'],['fontColor'],['fontSize'], 
     ]],
     // (min-width: 767)
     ['%767', [
        ['bold','italic', 'underline'],['list'],['fontColor'],['fontSize'], 
     ]],
     // (min-width: 480)
     ['%480', [
        ['bold','italic', 'underline'],['list'],['fontColor'],['fontSize'],
     ]]
 ]
}}
onChange={handleChange}
// defaultValue={editorContent}
setContents={editorContent}
// getSunEditorInstance={getSunEditorInstance}
/>       
 </Grid>

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      disabled={!inputChanged}
      onClick={()=>saveInformation()}
       >
        Save
      </Button>
    </DialogActions>
  </Dialog>
   
  )
}

export default AddInformation