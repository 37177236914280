import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import { Divider, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { v4 as uuidv4 } from 'uuid';
// css import
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { UpdateTemplateDataSpecificWithLoader } from '../../Helpers/ApiCall';
import { useEffect } from 'react';
import EditLocationModal from './EditLocationModal';
import { filterObjectsUptoLine } from '../../Helpers/HelperFunctions';

const AddLocationModal = (props) => {
    const {showLocationModal,setShowLocationModal,data,templateId,categoryId,lineUuid,type,commentUuid,title,addNewLocationModal,setAddNewLocationModal} = props
    // states
    const [inspectionResponse,setInspectionResponse] = useState();
    const [locationData,setLocationData] = useState();
    const [currentLocation,setCurrentLocation] = useState([]);
    const [currentLocationID,setCurrentLocationID] = useState();
    const [selectedItems,setSelectedItems] = useState([]);
    const [colOneLocationData,setColOneLocationData] = useState();
    const [colTwoLocationData,setColTwoLocationData] = useState();
    const [colThreeLocationData,setColThreeLocationData] = useState();
    const [editLocationModal,setEditLocationModal]=useState(false)
    const [defaultSelected,setDefaultSelected]=useState([])
    const [refresh,setRefresh] = useState(false);
    // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  // functions
  const handleClose=()=>{
        setShowLocationModal(false)
        setSelectedItems([]);
        setRefresh(prev=>!prev)
        // let arr=data?.data || ""
        // let arr2=[]
        // if (typeof data?.data == "string") {
        //   if(!arr){
        //     setCurrentLocation([]);
        //     return
        //   }
        //   arr2 = arr?.split(",")?.map((val)=>val.trim())
        //   setCurrentLocation(arr2);
        // }else if (typeof data?.data == "object") {
        //   if(!arr[0]){
        //     setCurrentLocation([]);
        //     return
        //   }
        //   if (arr[0]?.length !== 0) {
        //     arr2 = arr[0]?.split(",")?.map((val)=>val.trim())
        //   }
        //   setCurrentLocation(arr2);
        // }
    }
  const addLocationTextToArray=(uuid,value)=>{
    let arr= currentLocation
    // let uuidArray = selectedItems.map(el=>el.id)
    //   if (uuidArray.includes(uuid)) {
    //     const index = selectedItems.findIndex(item=>
    //       item.id === uuid
    //     );
    //     if (index > -1) {
    //       let arrval = selectedItems
    //       arrval.splice(index, 1)
    //       setSelectedItems(arrval)
    //     }
    //   } else {
    //     let arr = selectedItems;
    //     arr.push({id:uuid,value:value})
    //     setSelectedItems(arr)
    //   }
    //   let textValue = selectedItems.map(item=>item.value).join(", ");
    //   let idValue = selectedItems.map(item=>item.id).join(", ");
    //   setCurrentLocationID(idValue)
    //   setCurrentLocation(textValue)
    if(currentLocation?.includes(value)){
      // remove
      arr=currentLocation.filter(item=>{return item != value})
    }else{
      // add
      arr?.push(value)
    }
    setCurrentLocation([...arr])
     
  }  
  const clearLocationSelections=()=>{
    setSelectedItems([]);
    setCurrentLocation([])
  } 
  const addLocation=async()=>{
// ========================
    if(type === "comment") {
    let templateIndex =  inspectionResponse?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid) 
    let linIpIdx = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Location")
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
      let commentIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
        (itm) => itm.uuid == commentUuid
      );
      let locationIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
        commentIndex
      ]?.comment_input_controls?.findIndex((itm) => itm.type == "Location");
      if (locationIndex < 0) {
      let locationObject = {
        line_input_control_id: null,
        uuid: uuidv4(),
        prompt: "Location",
        values: [],
        type: "Location",
        data: currentLocation,
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),
      };    
        prevData?.inspectiontemplates[templateIndex]?.categories[
          categoryIndex
        ]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls?.push(locationObject);
      } else {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          locationIndex
        ].data =currentLocation;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          locationIndex
        ].updated_at = await GetUTCTime();
        // prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex]?.comment_input_controls[locationIndex].data = [currentLocation];
        // prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_input_controls[locationIndex].updated_at = await GetUTCTime();
      }
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
      
      setShowLocationModal(false)
    }
  else{
    let templateIndex =  inspectionResponse?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid) 
    let linIpIdx = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Location")
    let prevData = JSON.parse(JSON.stringify(inspectionResponse));
    delete prevData?.report_media_library_files;
  if (linIpIdx < 0) {
    let locationObject = {
      line_input_control_id: null,
      uuid: uuidv4(),
      prompt: "Location",
      values: [],
      type: "Location",
      data:currentLocation,
      updated_at: await GetUTCTime(),
      created_at: await GetUTCTime(),
    };
    prevData.inspectiontemplates[templateIndex]?.categories[
      categoryIndex
    ]?.lines[lineIndex]?.line_input_controls?.push(locationObject);
  } else {
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].lines[lineIndex].line_input_controls[linIpIdx].data =
    currentLocation;
      
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].updated_at = await GetUTCTime();
  }
  let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
  UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
  setShowLocationModal(false)
} 
}
    useEffect(() => {
        if(storeData.inspectionData){
             setInspectionResponse(storeData.inspectionData?.data)
             let templateIndex =  storeData.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
             let templateData = storeData.inspectionData?.data?.inspectiontemplates[templateIndex]?.location_data;
             setLocationData(templateData);
             let colOne = templateData?.filter(loc=>loc.col === '1')
             let colTwo = templateData?.filter(loc=>loc.col === '2')
             let colThree = templateData?.filter(loc=>loc.col === '3')
             setColOneLocationData(colOne)
             setColTwoLocationData(colTwo)
             setColThreeLocationData(colThree)
        }
    }, [storeData?.inspectionData,showLocationModal])
    
    // useEffect(() => {
    //   setCurrentLocation(data !== null && data !== undefined && data.data !== null && data.data.length !== 0 ? data.data[0]:'')
    // }, [data,refresh])

    // useEffect(()=>{
    //   let arr=data?.data || ""
    //   let arr2=[]
    //   if(typeof(data?.data[0]==="string")){setCurrentLocation(data?.data[0])}
    //   else{
    //     if(arr?.length !== 0){
    //       arr2=arr.split(", ")
    //     }
    //     setCurrentLocation(arr2)
    //   }
    //   // if(arr?.length >0){
    //   //   arr=data?.data
    //   // }
    
    // },[data])

    useEffect(() => {
      let arr = data?.data || "";
      let arr2 = [];
      if (typeof data?.data == "string") {
        if(!arr){
          setCurrentLocation([]);
          return
        }
          arr2 = arr?.split(", ")?.map((val)=>val.trim())
          setCurrentLocation(arr2);
      } else if (typeof data?.data == "object") {
        if(data?.data==null){
          setCurrentLocation([]);
          return
        }
        if(!data?.data[0]){
          setCurrentLocation([]);
          return
        }
        if (arr[0]?.length !== 0) {
          // arr2 = arr?.split(", ")?.map((val)=>val.trim())
          arr2=arr
        }

        setCurrentLocation(arr2);
      }
      setDefaultSelected(data?.data)
    }, [data]);

    useEffect(()=>{
    },[currentLocation])
    

    
  return (
    <>
    <EditLocationModal editLocationModal={editLocationModal} setEditLocationModal={setEditLocationModal} colOneLocationData={colOneLocationData} colTwoLocationData={colTwoLocationData} colThreeLocationData={colThreeLocationData} currentLocation={currentLocation} setShowLocationModal={setShowLocationModal} locationData={locationData} setColOneLocationData={setColOneLocationData} setColTwoLocationData={setColTwoLocationData} setColThreeLocationData={setColThreeLocationData} type={type} inspectionResponse={inspectionResponse} templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} commentUuid={commentUuid} addNewLocationModal={addNewLocationModal} setAddNewLocationModal={setAddNewLocationModal}/>
    <Dialog className='dialog-container-location'
    sx={{visibility:`${addNewLocationModal?'hidden':''}`}}
    open={showLocationModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-category">
      <CloseIcon onClick={handleClose} />
    </div>
    <DialogTitle className='category-dialog-title'>{data != undefined ? data?.prompt : title}</DialogTitle>
    <span className='category-dialog-subtitle'>Select descriptions or type custom text. </span>
    <Grid className="input-dialog">
          <Grid className="input-controll">
            <p> Location</p>
            <div className="location-input-text">
              {/* {locationTextArray && locationTextArray.length !== 0
                ? locationTextArray?.map((el, index) => {
                    return (
                      <span key={index} style={{ marginRight: "4px" }} className="location-display-word" onClick={() => handleClick(el.uuid)}>
                        {el.name}
                      </span>
                    );
                  })
                : ""} */}
                {/* ================== */}
                <FormControl sx={{ m: 0,width:'100%',margin:'0'}} variant="outlined">
              <OutlinedInput
                  id="outlined-adornment-weight"
                  size="small"
                  type="text"
                  fullWidth
                  sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                      },
                    }}
                  value={currentLocation}
                 onChange={(e)=>{
                    let newlocation=e.target.value 
                    let arr= newlocation?.split(",")
                    setCurrentLocation(arr)}}
                  endAdornment={
                      <InputAdornment position="end">
                      <IconButton   
                      className="close-icon" 
                          disableRipple               
                          aria-label="toggle password visibility"
                          onClick={()=>clearLocationSelections()}
                          edge="end"
                      >
                          {<CloseRoundedIcon onClick={()=>clearLocationSelections()}/>} <span className='edit-text'></span>
                      </IconButton>
                      </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Select Location"
              />
          </FormControl>
                {/* =================== */}
                {/* <Typography>"hello"</Typography> */}
                {/* <span  style={{ marginRight: "4px" }} className="location-display-word" >{
                typeof(currentLocation)=="string"? currentLocation : currentLocation?.join(", ")
                }</span> */}
                {/* <span className="close-icon" onClick={()=>clearLocationSelections()}><CloseRoundedIcon/></span> */}
            </div>
          </Grid>
        </Grid>
    <DialogContent className="dialog-content-category overflow-x-hide">
      <Grid container className="input-controll">
        <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" >
        {colOneLocationData?.length > 0 &&
                colOneLocationData?.map((item, index) => {
                  return (
                    <div
                    className="loc-item"
                    onClick={() => addLocationTextToArray(item.uuid, item.value)}
                    style={{ background:`${currentLocation?.includes(item.value)?'#EAF5FF':''}`,display: item.value === "" && "none"}} key={index}
                  >
                    {item.value}
                  </div>
                  );
                })}
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" >
        {colTwoLocationData?.length > 0 &&
                colTwoLocationData?.map((item, index) => {
                  return (
                    <div
                      className="loc-item"
                      onClick={() => addLocationTextToArray(item.uuid, item.value)}
                      style={{ background:`${currentLocation?.includes(item.value)?'#EAF5FF':''}`,display: item.value === "" && "none"}} key={index}
                    >
                      {item.value}
                    </div>
                  );
                })}
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="loc-item-wrapper" >
        {colThreeLocationData?.length > 0 &&
                colThreeLocationData?.map((item, index) => {
                  return (
                    <div
                      className="loc-item"
                      onClick={() => addLocationTextToArray(item.uuid, item.value)}
                      style={{ background:`${currentLocation?.includes(item.value)?'#EAF5FF':''}`,display: item.value === "" && "none"}} key={index}
                    >
                      {item.value}
                    </div>
                  );
                })}
        </Grid>

      </Grid>
    </DialogContent>
    <Grid className="location-action" onClick={()=>{setEditLocationModal(true)}}>     
          <p className="location-edit">
          <EditIcon /> Edit global location list
          </p>       
      </Grid>
    <Divider style={{width:'100%'}}/>
    <DialogActions className='category-modal-action'>          
      <Button className="add-category-btn w100 loc-btn"  onClick={()=>addLocation()}    
      >
        Done
      </Button>
    </DialogActions>
  </Dialog>   
  </>
  )
}

export default AddLocationModal