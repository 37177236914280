import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {  Grid, MenuItem, Select, Typography } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import DoneIcon from '@mui/icons-material/Done';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import '../../../Assets/Styles/Containers/_lines.scss';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import {  UpdateTemplateDataSpecificWithLoader } from '../../Helpers/ApiCall';
import { filterObjectsUptoLine } from '../../Helpers/HelperFunctions';

const AddLineDescriptionModal = (props) => {
  const {
    descriptionData,
    showAddDescriptionModal, 
    setShowAddDescriptionModal, 
    setShowCreateDescriptionModal, 
    descriptionArray, 
    setDescriptionArray,
    selectedDescription, 
    handleDescriptionSelection, 
    showTemperatureModal, 
    setShowTemperatureModal,
    showTextBoxModal,
    setShowTextBoxModal,
    is_saved,
    setISSaved,
    templateId, 
    categoryId, 
    lineUuid,
    line,
    editAadditionalInp,
    currentAdditionalInputID,
    descriptionPresent,
    addInputChanged,
    addInputPresent,
    setAddInputChanged,
    isInspDetails,
    showCreateDescriptionModal,
    newEmptyDescription,
    setNewEmptyDescription,
  } =props
    const [inspectionResponse,setInspectionResponse] = useState()
    // redux
  const [selectedTemperature, setSelectedTemperature] = useState("");
  const [selectedTextValue, setSelectedTextValue] = useState("");
  const [temperatureError, setTemperatureError] = useState(false);
  const [temperatureErrorText, setTemperatureErrorText] = useState("");
  const [inputError, setInputError] = useState(false);
  const [inputErrorText, setInputErrorText] = useState("");
  const [selectedTemperatureType, setSelectedTemperatureType] = useState("°F");
  const [inputChanged,setInputChanged]= useState(false);
  const [defalutDescriptionArray,setDefaultDescriptionArray] = useState([]);
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
    const handleClose=()=>{
        setISSaved(true);
        setShowAddDescriptionModal(false)
        setTemperatureError(false);
        setInputError(false);
        setInputErrorText("");
        setSelectedTextValue(selectedDescription)
        setInputChanged(false)
        setDescriptionArray(defalutDescriptionArray);
        if(!isInspDetails)setAddInputChanged(false)
    }
    const handleCreateNewDescription=()=>{
        setShowCreateDescriptionModal(true);
        setShowAddDescriptionModal(false);
        setInputChanged(true)
    }
    const handleTemperatureChange = (e) => {
      // const { value } = e.target;
      let temValue = e.target.value.trim()?.toUpperCase()?.replace(/[^-+.\d]/g,"")
      setSelectedTemperature(temValue);
      setTemperatureError(false);
      setTemperatureErrorText("");
      setInputChanged(true)
    }
    const validateTemperature = (e) => {
      const { value } = e.target;
      let check=e.target.value?.match(/^-?\d+(\.\d+)?$/g, '')
      if(value === "") {
        setTemperatureError(true);
        setTemperatureErrorText("Please fill the field");
      }else if(check===null){
        setTemperatureError(true);
        setTemperatureErrorText("Please enter valid numbers");
      } else {
        setTemperatureError(false);
        setTemperatureErrorText("");
      }
    }

    const handleInputValChange = (e) => {
      const isValidate = validateInput(e);
      if(isValidate){
        if(line?.prompt==="Year Built"){
          let yearbuilt = e.target.value.trim()?.replace(/[^\d]/g,"")
          setSelectedTextValue(yearbuilt);
        }
        else{
        // const { value } = e.target;
        let text=e.target.value
        setSelectedTextValue(text);
        setInputError(false);
        setInputErrorText("");
        setInputChanged(true) 
        }
    }else{
        setSelectedTextValue("");
      }
    }

    const validateInput = (e) => {
      const { value } = e.target;
      setInputChanged(true)
      if(value.trim() === "") {
        setInputError(true);
        setInputErrorText("Please fill the field");
        return false
      } else {
        setInputError(false);
        setInputErrorText("");
        return true
      }
    }

    const handleDecsription=async(decValue)=>{
      if(!temperatureError && !inputError) {
        if(editAadditionalInp){
          var Templates = storeData?.inspectionData?.data?.inspectiontemplates;
          var indexValue = Templates?.findIndex(itm => itm.template_id === templateId);
          var categoryIndex = Templates[indexValue]?.categories?.findIndex(itm => itm.id === categoryId);
          var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid);
          let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
          delete prevData?.report_media_library_files;
            let AdditionalInputControlIndex = Templates[indexValue]?.categories[
                categoryIndex
            ]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
                return (
                    itm.type === "Additional Input" &&
                    itm.line_input_control_id == currentAdditionalInputID
                );
            });
            if (AdditionalInputControlIndex >= 0) {
                let oldAddiObj = Templates[indexValue].categories[categoryIndex
                ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex];
                oldAddiObj.values = decValue; 
                oldAddiObj.updated_at = await GetUTCTime();
                prevData.inspectiontemplates[indexValue].categories[categoryIndex
                ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex] = oldAddiObj;
  
            }
            let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
            UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
            setShowAddDescriptionModal(false)
            setInputChanged(false)
            setISSaved(prev=>!prev)
            setShowTemperatureModal(false);
            setShowTextBoxModal(false);
        }else{
          if(showTemperatureModal || showTextBoxModal) handleDescriptionSelection(showTextBoxModal ? decValue : decValue);
        
          let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
          let categoryIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
          let lineIndex = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid)
          let linIpIdx = inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=> showTemperatureModal ? lineInpt.type === "Temperature" : showTextBoxModal ? lineInpt.type === "TextBox" : (lineInpt.type === "Description" || lineInpt.type === "CheckBox"))
          let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
          delete prevData?.report_media_library_files;
          let uuidValue = uuidv4();
          if(linIpIdx !== -1){   
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ][`${showTemperatureModal ? 'data' : showTextBoxModal ? 'data' : 'values'}`] = decValue;
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls[
              linIpIdx
            ].updated_at = await GetUTCTime();        
          }else{
            let discriptionObject = {
              line_input_control_id: null,
              uuid: uuidv4(),
              prompt: "Description",
              values: decValue,
              type: "Description",
              data: decValue?.filter(el=>el.is_selected === true).map(item=>item.value),
              updated_at: await GetUTCTime(),
              created_at: await GetUTCTime(),
            };
    
            prevData.inspectiontemplates[templateIndex].categories[
              categoryIndex
            ].lines[lineIndex].line_input_controls.push(discriptionObject);
          }
          let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
          UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
          setShowAddDescriptionModal(false)
          setInputChanged(false)
          setShowTemperatureModal(false);
          setShowTextBoxModal(false);
          if(!isInspDetails)setAddInputChanged(false)
        }
      }
      setNewEmptyDescription(false)
   } 
   useEffect(() => {
    setISSaved(false);
    if(storeData.inspectionData) setInspectionResponse(storeData)
    
    if(line?.type === "Temperature") {
      setSelectedTemperature(selectedDescription?.[0]?.split(' ')[0] === undefined ? "0" : selectedDescription?.[0]?.split(' ')[0]);
      setSelectedTemperatureType(selectedDescription?.[0]?.split(' ')[1] === undefined ? "°F" : selectedDescription?.[0]?.split(' ')[1]);
    }  

    if(line?.type === "TextBox") setSelectedTextValue(selectedDescription);
   
   }, [storeData.inspectionData, showTemperatureModal, selectedDescription,line])
   useEffect(() => {
    if(selectedDescription?.length === 1 && !descriptionPresent && !addInputChanged) handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)
    if(!isInspDetails && selectedDescription?.length === 1 && !addInputPresent && addInputChanged) {
      if(!newEmptyDescription) handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)
      if(!isInspDetails)setAddInputChanged(false)
    }
  }, [selectedDescription])
  useEffect(() => {
    let addedLine = JSON.parse(JSON.stringify(descriptionArray));
    setDefaultDescriptionArray(addedLine)
  }, [showAddDescriptionModal])
  return (
    <Dialog className='dialog-container-new-category'
    open={showAddDescriptionModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-line">
      <CloseIcon onClick={handleClose} id="close_description_modal"/>
    </div>
    <DialogTitle className='new-category-dialog-title'>
      {/* {descriptionArray?.length===0? "Add description":"Choose description"} */}
      {descriptionData === undefined ? "Choose description" : (descriptionData?.prompt === null ? "" : descriptionData?.prompt)}
      </DialogTitle>
    <span className='category-dialog-subtitle'>Default choices editable from the portal. </span>
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
      <Grid item xs={12} >
                {/* <Typography className='in_inspection'>In inspection</Typography> */}
                {
                  showTemperatureModal ?
                  <>
                    <TextField id="temperatur_input" className="input-textfield" size="small" variant="outlined"
                      type="text"
                      fullWidth
                      onChange={handleTemperatureChange}
                      onKeyDown={(e) => e.keyCode === 13 }
                      onBlur={validateTemperature}
                      value={selectedTemperature}
                      name="categoryName"
                      error={temperatureError}
                      helperText={temperatureErrorText}
                    />

                    <Select id="temperature_type" size="small" variant="outlined"
                      sx={{ marginTop: "15px", marginBottom: "15px" }}
                      type="select"
                      fullWidth
                      onChange={(e)=>(setSelectedTemperatureType(e.target.value),setInputChanged(true))}
                      value={selectedTemperatureType}
                      name="categoryName"
                      // error={descriptionError}
                      // helperText={descriptionErrorText}
                    >
                      <MenuItem value="°F" id="Fahrenheit">Fahrenheit</MenuItem>
                      <MenuItem value="°C" id="Celsius">Celsius</MenuItem>
                    </Select>
                  </>
                  :
                  showTextBoxModal ? 
                    <TextField id="textbox_input" className="input-textfield" size="small" variant="outlined"
                      type="text"
                      fullWidth
                      sx={{ marginBottom: "15px" }}
                      onChange={handleInputValChange}
                      value={selectedTextValue}
                      // onBlur={validateInput}
                      name="categoryName"
                      error={inputError}
                      helperText={inputErrorText}
                    />
                  :
                  descriptionArray?.map((description,index)=>(
                    <Typography id={`description_${index}`} onClick={()=>(handleDescriptionSelection(description?.value),setInputChanged(true))} key={index} className={`bordered__text ${selectedDescription?.includes(description?.value) && 'cat_selected'}`}
                    >  <span className='bordered__text__content'>{description?.value} {description?.type ? description?.type : ""}</span>
                      <DoneIcon className='cat_seleted_icon'/>
                    </Typography>
                  ))
                }
                
                {
                  !showTemperatureModal && !showTextBoxModal ?
                  <Typography className='bordered__text catIsFlex' onClick={handleCreateNewDescription} id='add_new_des_button'><AddCircleIcon/><span className='bordered__text__content'>Add new </span> </Typography>
                  : ""
                }
              </Grid>      

      </Grid>
    </DialogContent>
    <DialogActions className='new-category-modal-action'> 
     { !showTemperatureModal && !showTextBoxModal  ?
      addInputChanged?
      addInputPresent?
      inputChanged==true? 
      <Button className="add-category-btn" id="save_description"
      onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
       >
        Save
      </Button>
      :
      <Button className="add-category-btn" id="save_description"
      // onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
      // onClick={()=>(setShowAddDescriptionModal(false),setShowTemperatureModal(false),setShowTextBoxModal(false))}
      onClick={() => {
              
        if (showTextBoxModal && selectedTextValue == "") {
          setInputError(true);
          setInputErrorText("Please fill the field");
          return
        }
      
      setShowAddDescriptionModal(false);
      setShowTemperatureModal(false);
      setShowTextBoxModal(false);
    }}
       >
        Save
      </Button>
      :
      ""
      :
     descriptionPresent?inputChanged==true? 
     <Button className="add-category-btn" id="save_description"
      onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
       >
        Save
      </Button>
      :
      <Button className="add-category-btn" id="save_description"
      // onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
      // onClick={()=>(setShowAddDescriptionModal(false),setShowTemperatureModal(false),setShowTextBoxModal(false))}
      onClick={() => {
              
        if (showTextBoxModal && selectedTextValue == "") {
          setInputError(true);
          setInputErrorText("Please fill the field");
          return
        }
      
      setShowAddDescriptionModal(false);
      setShowTemperatureModal(false);
      setShowTextBoxModal(false);
    }}
       >
        Save
      </Button>
      :
      ""
      :
      inputChanged==true? 
      <Button className="add-category-btn" id="save_description"
      onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
       >
        Save
      </Button>
      :
      <Button className="add-category-btn" id="save_description"
      // onClick={()=>handleDecsription(showTemperatureModal ? { type: selectedTemperatureType, value: selectedTemperature } : showTextBoxModal ? selectedTextValue : descriptionArray)}
      // onClick={()=>(setShowAddDescriptionModal(false),setShowTemperatureModal(false),setShowTextBoxModal(false))}
      onClick={() => {
              
        if (showTextBoxModal && selectedTextValue == "") {
          setInputError(true);
          setInputErrorText("Please fill the field");
          return
        }
      
      setShowAddDescriptionModal(false);
      setShowTemperatureModal(false);
      setShowTextBoxModal(false);
    }}
       >
        Save
      </Button>
      }
    </DialogActions>
  </Dialog>
  )
}

export default AddLineDescriptionModal