import {
  Grid,
  Typography,
  Divider,
  FormControl,
  TextField,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Modal,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { UpdateMedia, UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import OutlinedButton from "../../Button/OutlinedButton";
import FilledButton from "../../Button/FilledButton";
import { useDropzone } from "react-dropzone";
import {
  filterObjectsUptoLine,
  getImageDimensions,
  resizeImage,
} from "../../../Helpers/HelperFunctions";
import Compressor from "compressorjs";
import DeleteConfirmationDialog from "../../../Common/Alert/DeleteConfirmationDialog";

const NamePlate = ({ data, toggle, templateId, setSelectedImages, categoryId, lineUuid, namePlatedata, categoryUuid, editemodelNum, seteditemodelNum, setOnDragToggle, onDragToggle, selectedMediaFiles, setSelectedMediaFiles }) => {
  const [modelNumber, setModelNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [inspectionResponse, setInspectionResponse] = useState();
  const [showserialModal, setShowserialModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editorContent, setEditorContent] = useState([]);
  const [image, setImage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [camera, setCamera] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [imageFormatErr, setImageFormatErr] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);

  const handleClose = () => {
    setShowserialModal(false);
    setModelNumber(
      data !== undefined && data?.data !== undefined && data?.data?.length !== 0
        ? data?.data?.modelNumber
        : ""
    );
    setSerialNumber(
      data !== undefined && data?.data !== undefined && data?.data?.length !== 0
        ? data?.data?.serialNumber
        : ""
    );
    if (data?.data?.image !== null) {
      let imgurl =
        storeData?.inspectionData?.data?.bucket_url === undefined
          ? ""
          : data?.data?.image?.onlineUrl === undefined
            ? ""
            : `${storeData?.inspectionData?.data?.bucket_url}${data?.data?.image?.thumbnail !== null
              ? data?.data?.image?.thumbnail
              : data?.data?.image?.onlineUrl
            }`;
      setImage(imgurl);
    } else setImage("");
    setImageFormatErr("");
  };

  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
  };

  const savenameplatedata = () => {
    SerialModalNumber();
    setTimeout(function () {
      if (typeof image === "object") {
        handleAddMedia(null, "save");
      }
    }, 3000);
  };

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const SerialModalNumber = async () => {
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (item) => item.template_id === templateId
      );
    let categoryIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines?.findIndex(
        (line) => line?.uuid === lineUuid
      );
    let linIpIdx =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[
        lineIndex
      ]?.line_input_controls?.findIndex(
        (lineInpt) => lineInpt.type === "Nameplate"
      );
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;
    let previousimage =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[linIpIdx]?.data?.image;
    let prioviouserial =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[linIpIdx]?.data?.serialNumber;
    let priviousmodel =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[linIpIdx]?.data?.modelNumber;
    if (linIpIdx !== -1) {
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].data = {
        modelNumber:
          modelNumber !== null
            ? modelNumber
            : modelNumber == ""
              ? modelNumber
              : priviousmodel,
        serialNumber:
          serialNumber !== null
            ? serialNumber
            : serialNumber == ""
              ? serialNumber
              : prioviouserial,
        image: image !== null ? previousimage : image,
      };
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
        await GetUTCTime();
    } else {
      let informationObject = {
        line_input_control_id: null,
        uuid: uuidv4(),
        prompt: "Nameplate",
        type: "Nameplate",
        data: {
          modelNumber: modelNumber,
          serialNumber: serialNumber,
          image: image === undefined && image === null ? image : "",
        },
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls.push(informationObject);
      setShowserialModal(false);
    }
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowserialModal(false);
  };
  const checkformat = (e, actionType) => {
    let file;
    if (actionType == "edit") {
      file = e?.target?.files[0];
    } else {
      file = e;
    }
    let fileType = null;
    if (
      file?.type === "image/jpeg" ||
      file?.type === "image/png" ||
      file?.type === "image/jpg"
    ) {
      fileType = "image";
      // setImage(e?.target?.files[0])
      setImage(file);
      setImageFormatErr("");
    } else {
      fileType = "video";
      setImageFormatErr("Selected file format is not supported.");
    }
  };

  const handleAddMedia = async (e, actionType) => {
    let formData = new FormData();
    let file;
    if (actionType === "edit") {
      file = e?.target?.files[0];
    } else if (actionType === "drag") {
      file = e;
    } else {
      file = image;
    }
    let fileType = null;
    if (
      file?.type === "image/jpeg" ||
      file?.type === "image/png" ||
      file?.type === "image/jpg"
    ) {
      fileType = "image";
      setImageFormatErr("");
    } else {
      fileType = "video";
    }
    if (fileType === "video" || file?.name?.toLowerCase()?.endsWith(".jfif")) {
      toast.error(`Selected file format is not supported.`);
    } else {
      let imgObj;
      let compressedFile;
      let thumbImage;
      const { imgWidth, imgHeight } = await getImageDimensions(file);
      const maxWidth = 320;
      const maxHeight = 320;
      const quality = 0.8;
      let newWidth, newHeight;
      if (imgWidth > imgHeight) {
        // Landscape orientation
        newHeight = maxHeight;
        newWidth = (maxHeight / imgHeight) * imgWidth;
      } else {
        // Portrait orientation
        newWidth = maxWidth;
        newHeight = (maxWidth / imgWidth) * imgHeight;
      }
      let thumbnailImage = await resizeImage(
        file,
        newWidth,
        newHeight,
        quality
      );
      thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, {
        type: "image/jpeg",
      });
      if (file?.size > 512000) {
        try {
          const compressedFilee = await compressImage(file);
          compressedFile = new File(
            [compressedFilee],
            `${compressedFilee.name}`,
            { type: "image/jpeg" }
          );
          imgObj = {
            id: uuidv4(),
            url: null,
            name: compressedFile.name,
            size: compressedFile.size,
            type: compressedFile.type,
            edited: false,
            newUrl: null,
            online: "online",
            filetype: fileType,
            localUrl: null,
            OriginUrl: null,
            editCount: 0,
            onlineUrl: null,
            orginName: null,
            thumbnail: null,
            is_deleted: 0,
            order: 0,
            updated_at: await GetUTCTime(),
            description: null,
            OriginlocalUrl: null,
            isOriginUploaded: true,
          };
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      } else {
        compressedFile = file;
        imgObj = {
          id: uuidv4(),
          url: null,
          name: compressedFile.name,
          size: compressedFile.size,
          type: compressedFile.type,
          edited: false,
          newUrl: null,
          online: "online",
          filetype: fileType,
          localUrl: null,
          OriginUrl: null,
          editCount: 0,
          onlineUrl: null,
          orginName: null,
          thumbnail: null,
          is_deleted: 0,
          order: 0,
          updated_at: await GetUTCTime(),
          description: null,
          OriginlocalUrl: null,
          isOriginUploaded: true,
        };
      }

      let img_obj_string = JSON.stringify(imgObj);
      let params = {};
      params = {
        image: compressedFile,
        video_thumbnail: thumbImage,
        template_id: templateId,
        category_id: categoryUuid,
        line_id: lineUuid,
        inspection_guid: sessionStorage.getItem("guid"),
        img_object: img_obj_string,
        img_type: "nameplate",
      };
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      // setTimeout(function() {
      UpdateMedia(dispatch, formData);
      setDragActive(false);
      //  setShowserialModal(false)
      //  setRefresh(prev=>!prev)
      // }, 3000);
      fileInput.current.value = null;
      fileInput2.current.value = null;
    }
  };

  const handleConfirmClickedMediaDelete = async () => {
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (item) => item.template_id === templateId
      );
    let categoryIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines?.findIndex(
        (line) => line.uuid === lineUuid
      );
    let linIpIdx =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[
        lineIndex
      ]?.line_input_controls?.findIndex(
        (lineInpt) => lineInpt.type === "Nameplate"
      );
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;
    if (linIpIdx !== -1) {
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
        await GetUTCTime();
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[
        linIpIdx
      ].data.image.is_deleted = 1;
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[linIpIdx].data.image.updated_at =
        await GetUTCTime();
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setDeleteAlert(false);
    }
  };
  useEffect(() => {
    setModelNumber(
      data !== undefined && data.data !== undefined && data.data.length !== 0
        ? data.data.modelNumber
        : ""
    );
    setSerialNumber(
      data !== undefined && data.data !== undefined && data.data.length !== 0
        ? data.data.serialNumber
        : ""
    );
    setImage(
      data !== undefined && data.data !== undefined && data.data.length !== 0
        ? data.data.image
        : ""
    );
    if (storeData.inspectionData) {
      setInspectionResponse(storeData);
    }
    if (data?.data?.image !== null) {
      let imgurl =
        storeData?.inspectionData?.data?.bucket_url === undefined
          ? ""
          : data?.data?.image?.onlineUrl === undefined
            ? ""
            : `${storeData?.inspectionData?.data?.bucket_url}${data?.data?.image?.thumbnail !== null
              ? data?.data?.image?.thumbnail
              : data?.data?.image?.onlineUrl
            }`;
      setImage(imgurl);
    } else setImage("");
  }, [storeData.inspectionData, data]);


  // Function to update an existing object by index
  function updateObjectByIndex(index, newData) {
    // If index is within bounds
    if (index >= 0 && index < selectedMediaFiles.length) {
      // Update object properties
      Object?.assign(selectedMediaFiles[index], newData);
    }
  }
  const updateNameplateMedia = async () => {
    selectedMediaFiles.forEach(async (item) => {
      item.is_deleted = 0;
      item.updated_at = await GetUTCTime();
      item.edited = false;
      item.editCount = 0;
      item.description = null;
      item.times_used += 1;
    })
    let templateIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
        (item) => item.template_id === templateId
      );
    let categoryIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories?.findIndex((item) => item.id === categoryId);
    let lineIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines?.findIndex(
        (line) => line?.uuid === lineUuid
      );
    let lineInputIndex =
      inspectionResponse?.inspectionData?.data?.inspectiontemplates[
        templateIndex
      ]?.categories[categoryIndex]?.lines[
        lineIndex
      ]?.line_input_controls?.findIndex(
        (lineInpt) => lineInpt.type === "Nameplate"
      );
    let prevData = JSON.parse(JSON.stringify(inspectionResponse?.inspectionData?.data));
    delete prevData?.report_media_library_files;
    let previousImage =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[lineInputIndex]?.data?.image;
    let previousSerialNumber =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[lineInputIndex]?.data?.serialNumber;
    let priviousmodel =
      prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]
        ?.lines[lineIndex]?.line_input_controls[lineInputIndex]?.data?.modelNumber;
    if (lineInputIndex !== -1) {
      updateObjectByIndex(0, { updated_at: await GetUTCTime() })
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[lineInputIndex].data = {
        modelNumber:
          modelNumber !== null
            ? modelNumber
            : modelNumber === ""
              ? modelNumber
              : priviousmodel,
        serialNumber:
          serialNumber !== null
            ? serialNumber
            : serialNumber === ""
              ? serialNumber
              : previousSerialNumber,
        image: selectedMediaFiles[0],
      };
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls[lineInputIndex].updated_at =
        await GetUTCTime();
    } else {
      let informationObject = {
        line_input_control_id: null,
        uuid: uuidv4(),
        prompt: "Nameplate",
        type: "Nameplate",
        data: {
          modelNumber: modelNumber,
          serialNumber: serialNumber,
          image: selectedMediaFiles[0],
        },
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),
      };
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].line_input_controls.push(informationObject);
      setShowserialModal(false);
    }
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setSelectedMediaFiles([]);
    setSelectedImages([])
  };



  const onDropFn = React.useCallback(
    (acceptedFiles, type) => {
      if (type === "outside" && selectedMediaFiles?.length === 0) {
        handleAddMedia(acceptedFiles[0], "drag");
      }
      else if (type === "outside" && selectedMediaFiles?.length > 0) {
        if (selectedMediaFiles?.length === 1) {
          if(selectedMediaFiles[0]?.is_report_media_library_file){
            if(selectedMediaFiles[0]?.media_type?.includes("video/")){
              toast.error("Selected file format is not supported.")
              setSelectedImages([]);
              setSelectedMediaFiles([]);
            }
            else if(selectedMediaFiles[0]?.media_type?.includes("image/")){
              updateNameplateMedia();
            }
          }
          else if (selectedMediaFiles[0]?.type?.startsWith("video/")){
            toast.error("Selected file format is not supported.")
            setSelectedImages([]);
            setSelectedMediaFiles([]);
          }
          else updateNameplateMedia();
        }
        else if (selectedMediaFiles?.length > 1) toast.error("Multiple files can not be selected for Nameplate")
      }
      else if (type === "outside" && selectedMediaFiles?.length === 0) {
        checkformat(acceptedFiles[0], "drag");
      }
    },
    [handleAddMedia]
  );

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/png",
    onDragOver: (event) => {
      setDragActive(true);
      // event.preventDefault();
    },
    onDragEnter: () => {
      // Your other code for onDragEnter
    },
    onDragLeave: () => {
      setDragActive(false);
      // Your other code for onDragLeave
    },
  });

  return (
    <>
      <>
        <Grid
          container
          className="container"
          sx={{ border: (isDragActive || dragActive || toggle) && "2px dashed #4282e2" }}
          {...getRootProps({
            onDrop: (event) => onDropFn(event.dataTransfer.files, "outside"),
          })}
          onClick={(e) => e?.stopPropagation()}
        >
          <Grid item xs={12} className="line_detail_des_title">
            <span className="details_title">Nameplate</span>
            <span
              className="details_action"
              id="edit_nameplate"
              onClick={() => setShowserialModal(true)}
            >
              <EditIcon />
              Edit
            </span>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control"
              style={{ paddingRight: "15px" }}
            >
              <label className="input-form-label" htmlFor="modelnumber">
                Model number
              </label>
              <span>
                {modelNumber !== "" && modelNumber !== null
                  ? `${modelNumber}`
                  : "No model number."}
              </span>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control"
              style={{ paddingLeft: "15px" }}
            >
              <label className="input-form-label" htmlFor="serialnumber">
                Serial number
              </label>
              <span>
                {serialNumber !== "" && serialNumber !== null
                  ? `${serialNumber}`
                  : "No serial number."}
              </span>
            </FormControl>
          </Grid>
          {image === "" ? (
            <Grid item xs={12}>
              {/* <Typography  className='add-media-content_for_line_details'>
            <input {...getInputProps()} ref={fileInput2} type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => handleAddMedia(e, "edit")} style={{ display: "none" }} />
            <div style={{marginTop:"20px"}}><AddCircleIcon/></div>
                  <div style={{textDecoration:"underline",color:"#4282e2"}}>Click to add media</div>
                  <div>or drag and drop</div>
            </Typography> */}

              <Typography
                className="add-media-content_for_line_details mt-0"
                id="add_nameplate_image"
                onClick={() => fileInput2.current.click()}
              >
                <input
                  ref={fileInput2}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => handleAddMedia(e, "edit")}
                  style={{ display: "none" }}
                />
                <div style={{ marginTop: "20px" }}>
                  <AddCircleIcon />
                </div>
                <div style={{ textDecoration: "underline", color: "#4282e2" }}>
                  Click to add nameplate
                </div>
                <div>or drag and drop </div>
              </Typography>
            </Grid>
          ) : (
            <Grid container className="image_container">
              <Grid item xs={12} sm={6} md={12} lg={12}>
                <input
                  ref={fileInput2}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => handleAddMedia(e, "edit")}
                  style={{ display: "none" }}
                />
                <img
                  className="image_container"
                  onClick={() => fileInput2?.current?.click()}
                  src={
                    typeof image === "string"
                      ? image
                      : URL?.createObjectURL(image)
                  }
                  alt="Thumb"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </>

      <>
        {showserialModal && (
          <Dialog
            className="dialog-container-new-option"
            open={showserialModal}
            onClose={handleClose}
            aria-labelledby="category-modal"
            aria-describedby="category-modal-description"
          >
            <div className="close-wrapper-category">
              <CloseIcon onClick={handleClose} />
            </div>
            <DialogTitle className="editor-dialog-title">
              {"Nameplate"}
            </DialogTitle>
            <DialogContent className="dialog-content-new-option">
              {/* <div  {...getRootProps()} 
          // onClick={(e) => e?.stopPropagation()}
          > */}
              {/* <input {...getInputProps()} /> */}
              <div
                className="add-media-nameplate"
                style={{ border: isDragActive && "2px dashed #4282e2" }}
              >
                {image === "" ? (
                  <>
                    <Typography
                      className="add-media-content_for_nameplate mt-0"
                      id="basic-button"
                      {...getRootProps({
                        onDrop: (event) =>
                          onDropFn(event.dataTransfer.files, "inside"),
                      })}
                      onClick={() => fileInput.current.click()}
                    >
                      <input
                        // {...getInputProps({onDrop: (event) => onDropFn(event.dataTransfer.files, "inside")})}
                        ref={fileInput}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={(e) => {
                          checkformat(e, "edit");
                        }}
                        style={{ display: "none" }}
                      />
                      <div style={{ marginTop: "20px" }}>
                        <AddCircleIcon />
                      </div>
                      <div
                        style={{
                          textDecoration: "underline",
                          color: "#4282e2",
                        }}
                      >
                        Click to add nameplate
                      </div>
                      <div>or drag and drop</div>
                    </Typography>
                  </>
                ) : (
                  <>
                    <input
                      // {...getInputProps({onDrop: (event) => onDropFn(event.dataTransfer.files, "inside")})}
                      ref={fileInput}
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => {
                        checkformat(e, "edit");
                      }}
                      style={{ display: "none" }}
                    />
                    <img
                      {...getRootProps({
                        onDrop: (event) =>
                          onDropFn(event.dataTransfer.files, "inside"),
                      })}
                      className="team-prof-img"
                      onClick={() => fileInput.current.click()}
                      style={{
                        maxHeight: "-webkit-fill-available",
                        maxWidth: "-webkit-fill-available",
                        height: "195px",
                      }}
                      src={
                        typeof image === "string"
                          ? image
                          : URL?.createObjectURL(image)
                      }
                      alt="Thumb"
                    />
                  </>
                )}
                {data?.data?.image === null ||
                  (data?.data?.image === undefined && image === "") ||
                  typeof image === "object" ? (
                  ""
                ) : (
                  <DeleteIcon
                    onClick={() => setDeleteAlert(true)}
                    sx={{
                      color: "Gray",
                      position: "absolute",
                      marginTop: "1rem",
                      marginRight: "321px",
                      ":hover": { cursor: "pointer", color: "red" },
                    }}
                  />
                )}
              </div>
              {/* </div> */}
              {<span style={{ color: "red" }}>{imageFormatErr}</span>}
              <br />
              <br />
              <Grid
                container
                className="input-controll"
                style={{ width: "100%" }}
              >
                <FormControl
                  variant="standard"
                  fullWidth
                  className="forms-control"
                >
                  <label className="input-form-label" htmlFor="modelnumber">
                    Model number
                  </label>
                  <TextField
                    id="modelnumber"
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder="Model number"
                    value={modelNumber}
                    onChange={(e) => setModelNumber(e.target.value)}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                className="input-controll"
                style={{ width: "100%" }}
              >
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="serialnumber">
                    Serial number
                  </label>
                  <TextField
                    id="serialnumber"
                    size="small"
                    variant="outlined"
                    placeholder="Serial number"
                    type="text"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions className="new-category-modal-action">
              <Button className="add-category-btn" onClick={savenameplatedata}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>

      <>
        {/* delete alert dialog */}
        <DeleteConfirmationDialog
          open={deleteAlert}
          handleClose={handleCloseDeleteAlert}
          title={"Are you sure you want to delete the nameplate image?"}
          contentText={`This action cannot be undone.`}
          cancelBtnTitle={"Cancel"}
          cancelBtnAction={handleCloseDeleteAlert}
          submitBtnTitle={"Delete"}
          submitBtnAction={handleConfirmClickedMediaDelete}
        />
        {/* delete dialog end */}
      </>
    </>
  );
};

export default NamePlate;
