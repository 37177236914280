import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState,useEffect,useCallback } from "react";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecificWithLoader } from "../../../Helpers/ApiCall";
import { useSelector,useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReorderList from "./ReorderList";
import update from "immutability-helper";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";

function EditDescription({
  descriptionData,
  data,
  prevDescriList,
  inspectionResponse,
  templateId,
  categoryId,
  lineUuid,
  setEditDescriptionModal,
  editDescriptionModal,
  setShowAddDesModal,
  showAddDescModal,
  showBackArrow,
  setDescriptionData,
  isAdditionalInfo,
  currentAdditionalInputID,
  line_input_control_id
}) {
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const [editDescriptionValues, setEditDescriptionValues] = useState([]);
  const [editDescriptionError, setEditDescriptionError] = useState(false);
  const [errorIndex, setErrorIndex] = useState([]);
  const [editDescriptionErrorMessage, setEditDescriptionErrorMessage] =
    useState("");
  //for add new
  const [editAddNew, setEditAddNew] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionName, setDescriptionName] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  // redux
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  // for add new
  const handleAddNewBackArrow = () => {
    setEditAddNew(false);
    setDescriptionName("");
    setEditDescriptionModal(true);
    // setInputChanged(false)
  }

  const handleEditAddNew = () => {
    // setEditDescriptionModal(false)
    setEditAddNew(true);
    // setInputChanged(true);
  };

  const validationAddNew = () => {
    setInputChanged(true);
    let availiableDescriptions = descriptionData?.values?.map(val => val?.value);
    if (availiableDescriptions?.includes(descriptionName)) {
      setDescriptionError(true);
      setDescriptionErrorMessage('This description is already on the list.')
    }
    else if (descriptionName?.trim() === "") {
      setDescriptionError(true);
      setDescriptionErrorMessage('Please add a description')
    }
    else {
      setDescriptionError(false);
      setDescriptionErrorMessage('');
      setEditDescriptionValues([...editDescriptionValues, { is_deleted: 0, is_selected: true, updated_at: "", uuid: uuidv4(), value: descriptionName }]);
      setEditAddNew(false)
      setDescriptionName("")
    }
    
  }

  // for edit
  const handleClose = () => {
    setInputChanged(false)
    setEditDescriptionModal(false);
  };

  const validation = () => {
    const index = editDescriptionValues?.filter((item) => {return(item?.value?.trim() == "" || item?.value?.trim() == null)&& item?.is_deleted==0});
    const arr= index.map((item,index)=>item.uuid)
    if (index?.length == 0) {
      if(!inputChanged){
        setEditDescriptionModal(false) 
        return
      }
      handleSave();
    } else {
      setErrorIndex(arr);
      setEditDescriptionError(true);
      setEditDescriptionErrorMessage("Please add a description");
    }
  };

  const deletematerial = (index) => {
    let data = editDescriptionValues;
    data[index].is_deleted = 1;
    setEditDescriptionValues([...data]);
    setInputChanged(true);
  };

  const handleSave = async () => {
    setLoader(true);
    if(isAdditionalInfo){
      editDescriptionValues.map(async (item, index) => {item.updated_at = await GetUTCTime();item.order = index;});
      const arr = editDescriptionValues.filter((item) => {return item.is_selected && item.is_deleted == 0;}).map((a) => a.value);
      var Templates = inspectionResponse?.inspectionData?.data?.inspectiontemplates;
      var indexValue = Templates?.findIndex(itm => itm.template_id === templateId);
      var categoryIndex = Templates[indexValue]?.categories?.findIndex(itm => itm.id === categoryId);
      var lineIndex = Templates[indexValue]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid);
      let prevData = {...inspectionResponse?.inspectionData?.data}
      delete prevData?.report_media_library_files;
        let AdditionalInputControlIndex = Templates[indexValue]?.categories[
            categoryIndex
        ]?.lines[lineIndex]?.line_input_controls?.findIndex((itm) => {
            return (
                itm.type === "Additional Input" &&
                itm.line_input_control_id == currentAdditionalInputID
            );
        });
        if (AdditionalInputControlIndex >= 0) {
        prevData.inspectiontemplates[indexValue].categories[categoryIndex
        ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].values=editDescriptionValues
        prevData.inspectiontemplates[indexValue].categories[categoryIndex
        ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].data=arr
        prevData.inspectiontemplates[indexValue].categories[categoryIndex
        ].lines[lineIndex].line_input_controls[AdditionalInputControlIndex].updated_at = await GetUTCTime()

        }else{
          const obj={
            data:arr || [],
            line_input_control_id:await uuidv4(),
            prompt:"Additional Input",
            type:"Additional Input",
            updated_at:await GetUTCTime(),
            uuid:await uuidv4(),
            values:editDescriptionValues || []
          }
          // responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls.push(obj)
          prevData.inspectiontemplates[indexValue].categories[categoryIndex
          ].lines[lineIndex].line_input_controls.push(obj)

        }
        setEditDescriptionModal(false);
        setInputChanged(false)
        setDescriptionName("");
        let filteredObject = filterObjectsUptoLine(prevData, indexValue, categoryIndex, lineIndex);
        UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
        setShowAddDesModal(false);
    }
    else{
    let prevData = {...inspectionResponse?.inspectionData?.data}
    delete prevData?.report_media_library_files;
    editDescriptionValues.map(async (item, index) => {item.updated_at = await GetUTCTime();item.order = index;});
    const arr = editDescriptionValues.filter((item) => {return item.is_selected && item.is_deleted == 0;}).map((a) => a.value);
    let templateIndex =inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let categoryIndex =inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex =inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line.uuid === lineUuid);
    let linIpIdx =inspectionResponse?.inspectionData?.data?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex((lineInpt) => lineInpt.type === "Description");
    let responseData = inspectionResponse?.inspectionData?.data;
    if (linIpIdx !== -1) {
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].values =editDescriptionValues;
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].data = arr;
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls[linIpIdx].updated_at =await GetUTCTime();
    }else{
      const obj={
        data:arr || [],
        line_input_control_id:await uuidv4(),
        prompt:"Description",
        type:"Description",
        updated_at:await GetUTCTime(),
        uuid:await uuidv4(),
        values:editDescriptionValues || []
      }
      responseData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].line_input_controls.push(obj)
    }
    setRefresh((prev) => !prev);
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredObject);
    setEditDescriptionModal(false);
    setDescriptionName("");
    setInputChanged(false)}
  };

  useEffect(() => {
    const arr = descriptionData?.values || [];
    const temp = JSON.stringify(arr);
    const temp2 = JSON.parse(temp);
    let finalArray = []
    for (let item of temp2) {
      if (item?.is_deleted == undefined) {
        item.is_deleted = 0
      } finalArray.push(item)
    }
    setEditDescriptionValues(finalArray);
  }, [descriptionData?.values]);

  useEffect(() => { }, [editDescriptionValues]);

  const updateDescription = (uuid, value) => {
    setInputChanged(true);
    const index = editDescriptionValues.findIndex((item) => {
      return uuid == item.uuid;
    });
    let array = editDescriptionValues;
    array[index].value = value;
    setEditDescriptionValues([...array]);
  };

  

  // reorder
  const moveCard = (dragIndex, hoverIndex) => {
    setEditDescriptionValues((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }

  const renderCard = (card, index) => {
    return (
      <ReorderList
      setInputChanged={setInputChanged}
      deletematerial={deletematerial}
      editDescriptionError={editDescriptionError}
      editDescriptionErrorMessage={editDescriptionErrorMessage}
      updateDescription={updateDescription}
      errorIndex={errorIndex}
        key={card.uuid}
        index={index}
        id={card.uuid}
        item={card}
        moveCard={moveCard}
      />
    );
  }


  return (
    <>
      {/*start edit popup */}
      {!editAddNew ? <Dialog
        className="dialog-container-edit-description"
        open={editDescriptionModal}
        onClose={handleClose}
        id="d"
        aria-labelledby="category-modal"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-line">
          <CloseIcon onClick={handleClose} />
        </div>
        <DialogTitle className="new-category-dialog-title">
          {/* {isAdditionalInfo ? "Edit list":"Edit description list"} */}
          {data === undefined ? "Description" : (data?.prompt === null ? "" : data?.prompt)}
        </DialogTitle>
        <DialogContent className="dialog-content-new-category" sx={{paddingLeft:"35px !important",paddingRight:"30px !important"}}>
          <Grid sx={{ display: "flex", flexFlow: "wrap"}}>
          {editDescriptionValues.map((item, index) => renderCard(item, index))}
            {/* {editDescriptionValues.map((item, index) => (
                <>
                
              </>
            ))} */}
          </Grid>
          <Grid >
              <Typography
                className="line_details__add_btn"
                onClick={handleEditAddNew}
              >
                <AddCircleIcon />
                <span className="line_details__add_text">Add new </span>
              </Typography>
            </Grid>
        </DialogContent>
        <DialogActions className="new-category-modal-action">
           
            <Button className="add-category-btn" onClick={validation}>
              Save
            </Button>
          
        </DialogActions>
      </Dialog> :
        <Dialog
          className="dialog-container-new-option"
          open={editAddNew}
          onClose={handleClose}
          aria-labelledby="category-modal"
          aria-describedby="category-modal-description"
        >
          <div className="close-wrapper-new-category">
            <ArrowBackIcon onClick={handleAddNewBackArrow} />
          </div>
          <DialogTitle className="new-category-dialog-title">
            {"Add new option"}
          </DialogTitle>

          <DialogContent className="dialog-content-new-option">
            <Grid container className="input-controll">
              <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control w-100">
                  <label className="input-form-label" id="categoryName">
                  {data === undefined ? "Description" : (data?.prompt === null ? "" : data?.prompt)}
                  </label>
                  <TextField
                    id="legacy_user"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    type="text"
                    onChange={(e) => { setDescriptionName(e.target.value)}}
                    value={descriptionName}
                    name="categoryName"
                    error={descriptionError}
                    helperText={descriptionErrorMessage}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="new-category-modal-action">
            <Button
              className="add-category-btn"
              onClick={validationAddNew}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>}
      {/*end edit add new */}
    </>
  );
}

export default EditDescription;
